module.exports = {
    sales_operations_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
       
        customerHistory: {            
            query: "WITH LatestClosedQuotes AS ("
            + "SELECT q1.nCompany, q1.id, q1.dtFirstClosed, "
            + "(SELECT MAX(q2.dtLastUpdated) "
            + " FROM RAC_CUSTOMER_QUOTES q2 "
            + " WHERE q2.nCompany = q1.nCompany "
            + " AND q2.sStatus = 'Discontinued' "
            + " AND q2.nRep = [nRep]) AS MaxDiscontinuedDate "
            + "FROM RAC_CUSTOMER_QUOTES q1 "
            + "WHERE q1.sStatus = 'Closed' "
            + "AND q1.nRep = [nRep] "
            + "AND q1.dtFirstClosed >= DATEADD(YEAR, -7, GETDATE()) "
            + "AND NOT q1.bDiscontinuedValid = 1 "
            + "AND q1.id = ("
                + "SELECT MAX(q2.id) "
                + "FROM RAC_CUSTOMER_QUOTES q2 "
                + "WHERE q2.nCompany = q1.nCompany "
                + "AND q2.sStatus = 'Closed' "
                + "AND q2.nRep = [nRep] "
                + "AND q2.dtFirstClosed >= DATEADD(YEAR, -7, GETDATE()) "
                + "AND NOT q2.bDiscontinuedValid = 1"
            + ")"
        + ") "
        + "SELECT l.nCompany, l.id AS Quote, l.dtFirstClosed, l.MaxDiscontinuedDate "
        + "FROM LatestClosedQuotes l "
        + "LEFT JOIN ("
            + "SELECT nCompany, COUNT(*) AS OpenOrders "
            + "FROM RAC_CUSTOMER_QUOTES "
            + "WHERE sStatus = 'Open' "
            + "AND nRep = [nRep] "
            + "GROUP BY nCompany"
        + ") o ON l.nCompany = o.nCompany "
        + "LEFT JOIN ("
            + "SELECT nQuote, COUNT(*) AS PendingSchedules "
            + "FROM RAC_SCHEDULE "
            + "WHERE dtCompleted IS NULL "
            + "GROUP BY nQuote"
        + ") s ON l.id = s.nQuote "
        + "LEFT JOIN ("
            + "SELECT nQuote, COUNT(*) AS RecentCompleted "
            + "FROM RAC_SCHEDULE "
            + "WHERE dtCompleted >= DATEADD(MONTH, -3, GETDATE()) "
            + "GROUP BY nQuote"
        + ") r ON l.id = r.nQuote "
        + "WHERE COALESCE(o.OpenOrders, 0) = 0 "
        + "AND COALESCE(s.PendingSchedules, 0) = 0 "
        + "AND COALESCE(r.RecentCompleted, 0) = 0 "
        + "AND l.MaxDiscontinuedDate < DATEADD(MONTH, -2, GETDATE()) "
        + "ORDER BY l.dtFirstClosed DESC;",
            
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets data about a rep's past customers.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "",
            whereUsed: [],
        },
        scheduleFollowup: {
            query: "INSERT INTO RAC_SCHEDULE (nQuote, nType, sNote, dtDate, nRep, dtAdded, nAdded, nStatus, nDuration) VALUES ([nQuote], 0, '[sNote]', '[dtDate]', [nRep], GETDATE(), [nAdded], 0, 0)",
            req: ["nQuote", "sNote", "dtDate", "nRep", "nAdded"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Creates a new followup",
            tables: ["RAC_SCHEDULE"],
            type: "INSERT INTO",
            whereUsed: [],
        },
        leaveNote: {
            query: "INSERT INTO RAC_QUOTE_NOTES (nQuote, sNote, nRep, nType, dtTimeStamp, nAttention, nAttentionPriority, nCategory) VALUES ([nQuote], '[sNote]', [nRep],1,GETDATE(),0,0,8)",
            req: ["sNote", "nRep", "nQuote"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Leaves a note",
            tables: ["RAC_QUOTE_NOTES"],
            type: "INSERT INTO",
            whereUsed: [],
        },
        completeFollowup: {
            query: "UPDATE RAC_SCHEDULE SET dtCompleted=GETDATE(), nCompleted=[nCompleted], nStatus=1 WHERE id=[id]",
            req: ["nCompleted", "id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Completes a followup",
            tables: ["RAC_SCHEDULE"],
            type: "UPDATE",
            whereUsed: [],
        },
        updateFollowup: {
            query: "UPDATE RAC_SCHEDULE SET sNote='[sNote]', dtDate='[dtDate]' WHERE id=[id]",
            req: ["sNote", "dtDate", "id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        rep_followups: {
            query: "SELECT * FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL ORDER BY dtDate asc",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followups",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_quotes: {
            query: "SELECT rcq.*, "
                + "(SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany=rcq.nCompany and sStatus='Closed') AS ClosedQuotes, "
                + "(SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany=rcq.nCompany and sStatus='Open') AS OpenQuotes, "
                + "(SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq2.nCompany=rcq.nCompany and sStatus='Discontinued') AS DiscontinuedQuotes "
                + " FROM RAC_CUSTOMER_QUOTES rcq WHERE id IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup quotes",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_quote_lines: {
            query: "SELECT * FROM RAC_QUOTE_LINES WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup quote lines",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_POs: {
            query: "SELECT * FROM RAC_POS WHERE nAttachedQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup POs",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_RFQs: {
            query: "SELECT * FROM RAC_RFQ WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup RFQs",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_notes: {
            query: "SELECT * FROM RAC_QUOTE_NOTES WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup notes",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_emails: {
            query: "SELECT * FROM RAC_QUOTE_EMAILS WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup emails",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
    }
} 