// Trims a number to the second decimal value
// To Fixed provides it back as a string, so Math.Round is need to keep it as a integer
export function round(value) {
  return Number(Math.round(value + 'e' + 2) + 'e-' + 2).toFixed(2);
}

export function sortData(passedState, potential) {
  let orderCount = 0;
  for (let i = 0; i <= passedState.length - 1; i++) {
    if (passedState[i].nPotential === potential) {
      orderCount += passedState[i].nCount;
    }
  }
  return orderCount;
}

export function sortDataQuote(passedState, potential, quoteType) {
  let orderCount = 0;
  for (let i = 0; i <= passedState.length - 1; i++) {
    if (
      passedState[i].nPotential === potential &&
      passedState[i].sQuoteType === quoteType
    ) {
      orderCount += passedState[i].nCount;
    }
  }
  return orderCount;
}

//

export function findTotals(passedState) {
  let orderCount = 0;
  for (let i = 0; i <= passedState.length - 1; i++) {
    orderCount += passedState[i].nCount;
  }
  return orderCount;
}

// Counts the total of nCount based on the quoteType - Used in PieChart, BarChart, LineChart components

export function findTotalsQuote(passedValue, quoteType) {
  let orderCount = 0;
  // Subtracts 1 to adjust for indexing
  for (let i = 0; i <= passedValue.length - 1; i++) {
    if (passedValue[i].sQuoteType === quoteType) {
      orderCount += passedValue[i].nCount;
    }
  }
  return orderCount;
}

// // Calculated Pre Tax Margin amount using provided variables.
// export function calcPreTaxMargin(nQuoteTotal, nTaxRate, nPoTotals) {
//   let preTaxTotal = 0;
//   preTaxTotal += nQuoteTotal / (1 + nTaxRate / 100);
//   return preTaxTotal - nPoTotals;
// }

// Calculated Pre Tax Margin amount using provided variables.
export function calcPreTaxMargin(
  nQuoteTotal,
  nTaxRate,
  nPoTotals,
  bTaxDel,
  nDelivery
) {
  if (bTaxDel === true) {
    return nQuoteTotal / (1 + nTaxRate / 100) - nPoTotals;
  } else {
    return (
      (nQuoteTotal - nDelivery) / (1 + nTaxRate / 100) - nPoTotals + nDelivery
    );
  }
}

// Calculated Pre Tax Margin amount using provided variables.
export function calcEstimatedCommission(
  nQuoteTotal,
  nTaxRate,
  nPoTotals,
  bTaxDel,
  nDelivery
) {
  if (bTaxDel === true) {
    return (nQuoteTotal / (1 + nTaxRate / 100) - nPoTotals) / 10;
  } else {
    return (
      ((nQuoteTotal - nDelivery) / (1 + nTaxRate / 100) -
        nPoTotals +
        nDelivery) /
      10
    );
  }
}

// Calculated Pre Sales Tax amount using provided variables.
export function calcPreSalesTax(nQuoteTotal, nTaxRate, bTaxDel, nDelivery) {
  if (bTaxDel === 1) {
    return nQuoteTotal / (1 + nTaxRate / 100);
  } else {
    return (nQuoteTotal - nDelivery) / (1 + nTaxRate / 100) + nDelivery;
  }
}

export function calcPreSalesTaxCommissionLog(
  nQuoteTotal,
  nTaxRate,
  bTaxDel,
  nDelivery
) {
  let preTaxTotal = 0;

  if (bTaxDel === 1) {
    preTaxTotal += nQuoteTotal / (1 + nTaxRate / 100);
  } else {
    preTaxTotal += (nQuoteTotal - nDelivery) / (1 + nTaxRate / 100) + nDelivery;
  }

  return preTaxTotal;
}

// Calculates the total margin for 6 weeks
// Uses calcTotalMargin function in loop to provide non sales tax numbers
// calcPreSalesTax function is used to provide non sales tax numbers

export function calcTotalMargin(passedState) {
  let margin = 0;
  let nQuote7WeeksTotal = 0;
  for (let i = 0; i <= passedState.length - 1; i++) {
    margin += calcPreTaxMargin(
      passedState[i].nQuoteTotal,
      passedState[i].nTaxRate,
      passedState[i].nPoTotals,
      passedState[i].bTaxDel,
      passedState[i].nDelivery
    );
    nQuote7WeeksTotal += calcPreSalesTax(
      passedState[i].nQuoteTotal,
      passedState[i].nTaxRate,
      passedState[i].bTaxDel,
      passedState[i].nDelivery
    );
  }
  return (margin / nQuote7WeeksTotal) * 100;
}

// Calculates the total of sales for 6 weeks
// Uses calcPreSalesTax function in loop to provide non sales tax numbers

export function calcTotalSale(passedState) {
  let orderCount = 0;
  for (let i = 0; i <= passedState.length - 1; i++) {
    orderCount += calcPreSalesTax(
      passedState[i].nQuoteTotal,
      passedState[i].nTaxRate,
      passedState[i].bTaxDel,
      passedState[i].nDelivery
    );
  }
  return orderCount;
}

export function calcActiveSalesTotal(passedState) {
  let total = 0;
  for (let i = 0; i <= passedState.length - 1; i++) {
    total += calcPreSalesTax(
      passedState[i].nQuoteTotal,
      passedState[i].nTaxRate,
      passedState[i].bTaxDel,
      passedState[i].nDelivery
    );
  }
  return total;
}

export function calcActiveMarginTotal(passedState) {
  let total = 0;
  for (let i = 0; i <= passedState.length - 1; i++) {
    let preTaxTotal = 0;
    if (passedState[i].bTaxDel) {
      preTaxTotal +=
        passedState[i].nQuoteTotal / (1 + passedState[i].nTaxRate / 100) -
        passedState[i].nPoTotals;
        console.log("Pre tax total" + [i]);
        console.log(passedState[i].nQuoteTotal);
        console.log(passedState[i].nPoTotals);
        console.log(passedState[i].nTaxRate);
    } else {
      preTaxTotal +=
        (passedState[i].nQuoteTotal - passedState[i].nDelivery) /
          (1 + passedState[i].nTaxRate / 100) -
        passedState[i].nPoTotals +
        passedState[i].nDelivery;
        console.log("Pre tax total" + [i]);
        console.log(preTaxTotal);
    }
    total += preTaxTotal;
  }
  return total;
}

// Returns the reps name based on their rep number

export function whichRep(repNumber) {
  let getRep = repNumber

  if(getRep === "" || getRep === null){
    getRep = 0
  }


  switch (getRep) {
    case 0:
      return 'No Rep';
    case 1:
      return 'John Beagle';
    case 2:
      return 'Darcy Mann';
    case 3:
      return 'Janine Foster';
    case 4:
      return 'Jessica Keeton';
    case 5:
      return 'Andy Wendt';
    case 6:
      return 'Chris Spencer';
    case 7:
      return 'Bill Schiering';
    case 8:
      return 'Tony Cipollone';
    case 9:
      return 'Adam Lewis';
    case 12:
      return 'Robert Bradford';
    case 13:
      return 'Sheron Whisman';
    case 14:
      return 'Susan Beagle';
    case 15:
      return 'Jennifer Mccurley';
    case 16:
      return 'Brian Wood';
    case 17:
      return 'Josh Van Cleave';
    case 18:
      return 'Scott Gardner';
    case 19:
      return 'Valerie Ruebush';
    case 20:
      return 'Melissa Lewis';
    case 21:
      return 'Randy Lewis';
    case 22:
      return 'Randy Moore';
    case 23:
      return 'Jeremy Uitto';
    case 24:
      return 'Jason Patrick';
    case 25:
      return 'Kyle Thompson';
    case 26:
      return 'Daniel Cust';
    case 27:
      return 'Robert Beagle';
    case 28:
      return 'Operations';
    case 29:
      return 'Joel Thompson';
    case 30:
      return 'Miranda Schumaker';
    case 31:
      return 'Kyle Stamper';
    case 32:
      return 'Allissa Wilson';
    case 33:
      return 'Ashley Kelly';
    case 34:
      return 'Jake Nolan';
    case 35:
      return 'Bryan Schermann';
    case 36:
      return 'Alex Kavanagh';
    case 37:
      return 'Rhonda Brown';
    case 38:
      return 'Anita Abrams';
    case 39:
      return 'Joe Chapman';
    case 40:
      return 'Kristen Fisher';
    case 41:
      return 'Debbie Taylor';
    case 44:
      return 'Kevin Boles';
    case 45:
      return 'Johnny Beagle';
    case 46:
      return 'Vincent Florio';
    case 47:
      return 'Noah Draut';
    case 48:
      return 'Devon Heidmann';
    case 49:
      return 'Jalynn Hicks';
    case 50:
      return 'Noah Noviski';
    case 51:
      return 'Mark Gillaugh';
    case 52:
      return 'Nathan Wendt';
    case 53:
      return 'Matthew Damron';
    case 54:
      return 'Brian Kubala';
    case 55:
      return 'Matt Dameron';
    case 56:
      return 'Jayla Corley';
    case 57:
      return 'Kyle Snider';
    case 58:
      return 'Alex Kesson';
    case 59:
      return 'Tony Denier';
    case 60:
      return 'Brandon Helton';
    case 61:
      return 'Ryan Thacker';
    case 62:
      return 'Reagan Reeve';
    case 63:
      return 'Hunter Stewart';
    case 64:
      return 'Jeff Bruck';
    case 65:
      return 'Michael Taylor';
    case 66:
      return 'Larry Long';
    case 67:
      return 'Kirsten Richards';
    case 68:
      return 'Kevin Thompson';
    case 69:
      return 'Gracie Shepherd';
    case 71:
      return 'Jill Staley';
    case 72:
      return 'Austin Stuckey';
    case 73:
      return 'Greg Petrosh';
    default:
      return repNumber;
  }
}

export function activeReps() {
  return [
    {name: "No Rep", id: 0}, 
    {name: "Andy Wendt", id: 5}, 
    {name: "Tony Cipollone", id: 8}, 
    {name: "Randy Moore", id: 22}, 
    {name: "Kevin Boles", id: 44}, 
    {name: "Johnny Beagle", id: 45},  
    {name: "Mark Gillaugh", id: 51}, 
    {name: "Brian Kubala", id: 54}, 
    {name: "Hunter Stewart", id: 63},
    {name: "Jeff Bruck", id: 64},
    {name: "Kevin Thompson", id: 68},
    {name: "Greg Petrosh", id: 73},
  ]
}

export function activeInstallers() {
  return [44, 46, 63, 68]
}

export function activeCoordinators(){
  return [65]
}

export function getPaymentTerms(){
  return ['No Terms', 'Credit Card', 'Net 30', 'Net 60', 'Net 90', 'Due Upon Completion', '50% Down / 50% On Completion', 'Credit Hold']
}

export function userCanChangeTerms( getUser ){
  return ( getUser === 1 || getUser === 5 || getUser === 14 || getUser === 40 || getUser === 45 )
}

// Formats js date in MM/DD/YYYY - HH:MM AM Or PM

export function dateFormatter(date) {
  if (date != null) {
    let newDate = new Date(date+1000*3600*4);

    let sMonth = padValue(newDate.getMonth() + 1);
    let sDay = padValue(newDate.getDate());
    let sYear = newDate.getFullYear();
    let sHour = newDate.getHours();
    let sMinute = padValue(newDate.getMinutes());
    let sAMPM = 'AM';

    let iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = 'PM';
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = '12';
    }

    sHour = padValue(sHour);

    return (
      sMonth +
      '-' +
      sDay +
      '-' +
      sYear +
      ' - ' +
      sHour +
      ':' +
      sMinute +
      ' ' +
      sAMPM
    );
  }
}
export function dateTimeFormat(date) {
  if (date != null) {
    let newDate = new Date(date);

    let sMonth = padValue(newDate.getMonth() + 1);
    let sDay = padValue(newDate.getDate());
    let sYear = newDate.getFullYear();
    let sHour = newDate.getHours();
    let sMinute = padValue(newDate.getMinutes());
    let sAMPM = 'AM';

    let iHourCheck = parseInt(sHour);
    console.log(iHourCheck)

    if (iHourCheck > 12) {
      sAMPM = 'PM';
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = '12';
    }

    sHour = padValue(sHour);

    return (
      sMonth +
      '-' +
      sDay +
      '-' +
      sYear +
      ' - ' +
      sHour +
      ':' +
      sMinute +
      ' ' +
      sAMPM
    );
  }
}

export function dateFormat(date) {
  if (date != null) {
    let newDate = new Date(date);

    let sMonth = padValue(newDate.getMonth() + 1);
    let sDay = padValue(newDate.getDate());
    let sYear = newDate.getFullYear();

    return (
      sMonth +
      '-' +
      sDay +
      '-' +
      sYear
    );
  }
}

function padValue(value) {
  return value < 10 ? '0' + value : value;
}

export function nQuoteTotalFormatter(nQuoteTotal) {
  if (nQuoteTotal != null && nQuoteTotal != 0) {
    return (
      '$' +
      nQuoteTotal.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
  } else {
    return '$0.00'
  }
}
export function nQuoteTotalNoDollar(nQuoteTotal) {
  if (nQuoteTotal != null && nQuoteTotal != 0) {
    return (      
      nQuoteTotal.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
  } else {
    return '0.00'
  }
}

export function abbreviateBrand(sQuoteType) {
  if (sQuoteType === 'Rentacomputer.com') {
    return 'RAC';
  } else if (sQuoteType === 'CameraSecurityNow.com') {
    return 'CSN';
  } else {
    return 'CAM';
  }
}

export function calcMilesFromLatLong(coords1, coords2){
  if(coords1.latitude !== 0 && coords2.latitude !== 0){
    let R = 6371000
    let c1 = coords2.latitude * Math.PI/180
    let c2 = coords1.latitude  *Math.PI/180
    let c3 = (coords1.latitude - coords2.latitude) * Math.PI/180
    let c4 = (coords1.longitude - coords2.longitude) * Math.PI/180

    let a = Math.sin(c3/2) * Math.sin(c3/2) + Math.cos(c1) * Math.cos(c2) * Math.sin(c4/2) * Math.sin(c4/2)
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

    let d = R * c

    return d * .000621371
  }
}

export function formatMStoDays(getMS){
  if(parseFloat(getMS)>0){
    let days = Math.floor(getMS/1000/3600/24)
    let hours = Math.floor((getMS-days*1000*3600*24)/1000/3600)
    let minutes = Math.floor((getMS-days*1000*3600*24-hours*1000*3600)/1000/60)
    if (minutes < 10){minutes = "0" + minutes}
    let seconds = Math.floor((getMS-days*1000*3600*24-hours*1000*3600-minutes*1000*60)/1000)
    if (seconds < 10){seconds = "0" + seconds}

    if(days>0){
      return days + " d " + hours + ":" + minutes + ":" + seconds 
    } else {
      return hours + ":" + minutes + ":" + seconds
    }
  } else {
    return ""
  }
  
}

export const cRed = '#f88687'
export const cWhite = '#fff'
export const cGreen = '#a0db8e'
export const cGreenText = '#3c7e28'
export const cYellow = '#fff2cc'

export function marginColorCam(margin) {
  if (margin <= 29) {
    return cRed; // Red
  } else if (margin >= 30 && margin <= 39) {
    return cWhite; // White
  } else if (margin >= 40 && margin <= 55) {
    return cGreen; // Green
  } else {
    return cYellow; // Yellow
  }
}

export function marginColorRac(margin) {
  if (margin <= 23) {
    return '#ff6666'; // Red
  } else if (margin >= 24 && margin <= 37.4) {
    return '#fff'; // White
  } else if (margin >= 37.5 && margin <= 55) {
    return '#85bb65'; // Green
  } else {
    return '#fffd6e'; // Yellow
  }
}

export function dailyMarginColorRac(margin) {
  if (margin <= 3000) {
    return '#ff6666'; // Red
  } else if (margin >= 3000 && margin <= 9000) {
    return '#fff'; // White
  } else if (margin >= 9000 && margin <= 15000) {
    return '#85bb65'; // Green
  } else {
    return '#fffd6e'; // Yellow
  }
}

export function calcUpperBound(data){
  let top = Math.max(...data)
  let zeros = Math.floor(Math.log10(top/1.4))
  let leftSide = Math.ceil(top / Math.pow(10, zeros))
  if(leftSide > 6){
    leftSide = Math.ceil(leftSide/2)*2
  }

  let upperBound = leftSide * Math.pow(10, zeros)
  return upperBound
}