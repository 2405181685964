module.exports = {
    commission_log_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getLastPeriodLog: {
            query: "SELECT * FROM RAC_CUSTOMER_QUOTES WHERE dtPaid >= '[start]' AND dtPaid <= '[end]'",
            req: ["start", "end"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all quote info that was marked as paid within a given date range.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getLastPeriodLines: {
            query: "SELECT * FROM RAC_QUOTE_LINES WHERE nQuote IN (SELECT id FROM RAC_CUSTOMER_QUOTES WHERE dtPaid >= '[start]' AND dtPaid <= '[end]')",
            req: ["start", "end"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all quote lines from quotes that were marked as paid within a given date range.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getLastPeriodPOs: {
            query: "SELECT * FROM RAC_POS WHERE nAttachedQuote IN (SELECT id FROM RAC_CUSTOMER_QUOTES WHERE dtPaid >= '[start]' AND dtPaid <= '[end]')",
            req: ["start", "end"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all POs from quotes that were marked as paid within a given date range.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getLastPeriodPOLines: {
            query: "SELECT * FROM RAC_PO_LINES WHERE nQuote IN (SELECT id FROM RAC_POS WHERE nAttachedQuote IN (SELECT id FROM RAC_CUSTOMER_QUOTES WHERE dtPaid >= '[start]' AND dtPaid <= '[end]'))",
            req: ["start", "end"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all PO lines from quotes that were marked as paid within a given date range.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
    }
} 