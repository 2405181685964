import moment from "moment";

export function followupMetrics(followups){
    let metrics = {
        late: 0,
        today: 0,
        tomorrow: 0,
        busDays2: 0,
        busDays3: 0,
        busDays4: 0,
        busDays5: 0,
        thisMonth: 0,
        thisQuarter: 0,
        thisYear: 0,
        nextYear: 0,
    }

    let now = moment().utc();
    let endOfToday = moment().utc().endOf("day");
    let tomorrow = moment().utc().add(1, "days").endOf("day");
    let busDays2 = moment().utc().add(2, "days").endOf("day");
    let busDays3 = moment().utc().add(3, "days").endOf("day");
    let busDays4 = moment().utc().add(4, "days").endOf("day");
    let busDays5 = moment().utc().add(5, "days").endOf("day");
    let thisMonth = moment().utc().add(1, "month").endOf("day");
    let thisQuarter = moment().utc().add(3, "month").endOf("day");
    let thisYear = moment().utc().add(12, "month").endOf("day");

    for(let i=0; i<followups.length; i++){
        followups[i].dtDate = moment(followups[i].dtDate).utc().format("YYYY-MM-DDTHH:mm")
        let date = moment(followups[i].dtDate).utc()

        if (date.isBefore(now)) {
            metrics.late++
        } else if (date.isSameOrBefore(endOfToday)) {
            metrics.today++
        } else if (date.isSameOrBefore(tomorrow)){
            metrics.tomorrow++
        } else if (date.isSameOrBefore(busDays2)){
            metrics.busDays2++
        } else if (date.isSameOrBefore(busDays3)){
            metrics.busDays3++
        } else if (date.isSameOrBefore(busDays4)){
            metrics.busDays4++
        } else if (date.isSameOrBefore(busDays5)){
            metrics.busDays5++
        } else if (date.isSameOrBefore(thisMonth)){
            metrics.thisMonth++
        } else if (date.isSameOrBefore(thisQuarter)){
            metrics.thisQuarter++
        } else if (date.isSameOrBefore(thisYear)){
            metrics.thisYear++
        } else {
            metrics.nextYear++
        }
    }
    return metrics
}