import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { nQuoteTotalNoDollar, whichRep } from '../../../assets/functions/calculations';
import { percentTwoDecimal } from '../../../assets/functions/display';



export default class RepLeadTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Rep</th>
              <th>New Customer</th>
              <th>Web</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Chat</th>
              <th>Other</th>
              <th>Repeat</th>
              <th>Spam</th>
              <th>Total</th>
              <th>High Potential</th>
              <th>RFQs Sent <br />per Quote</th>
              <th>RFQs Sent <br />% of Quotes</th>
              <th>RFQs Full Match <br />per Quote</th>
              <th>RFQs Full Match <br />% of Quotes</th>
            </tr>
          </thead>
          <tbody>
            {this.props.reps.map((rep, index)=>(
              <>
              {rep.Total && (
                <tr key={index}>
                  <td>{whichRep(rep.nRep)}</td>
                  <td>{rep.NewCustomer.count}</td>
                  <td>{rep.Web.count}</td>
                  <td>{rep.Phone.count}</td>
                  <td>{rep.Email.count}</td>
                  <td>{rep.Chat.count}</td>
                  <td>{rep.Other.count}</td>
                  <td>{rep.Repeat.count}</td>
                  <td>{rep.Spam.count}</td>
                  <td>{rep.Total.count}</td>
                  <td>{rep.HighPotential.count}</td>
                  <td>{Math.round(rep.RFQsSent.count/rep.Total.count*10)/10}</td>
                  <td>{Math.round(rep.RFQsSent.quotes/rep.Total.count*1000)/10}%</td>
                  <td>{Math.round(rep.RFQsFullMatch.count/rep.Total.count*10)/10}</td>
                  <td>{Math.round(rep.RFQsFullMatch.quotes/rep.Total.count*1000)/10}%</td>
                  {/*
                  <td>{rep.RFQsSent.count}
                    &nbsp;({Math.round(rep.RFQsSent.count/rep.Total.count*10)/10}) / 
                    &nbsp;{rep.RFQsSent.quotes} 
                    &nbsp;({Math.round(rep.RFQsSent.quotes/rep.Total.count*1000)/10}%)
                  </td>
                  <td>{rep.RFQsFullMatch.count}
                    &nbsp;({Math.round(rep.RFQsFullMatch.count/rep.Total.count*10)/10}) / 
                    &nbsp;{rep.RFQsFullMatch.quotes} 
                    &nbsp;({Math.round(rep.RFQsFullMatch.quotes/rep.Total.count*1000)/10}%)
                  </td>
                  */}
                </tr>
              )}
              
              </>
            ))}
            <tr>
                  <td>Total</td>
                  <td>{this.props.metrics.NewCustomer.count}</td>
                  <td>{this.props.metrics.Web.count}</td>
                  <td>{this.props.metrics.Phone.count}</td>
                  <td>{this.props.metrics.Email.count}</td>
                  <td>{this.props.metrics.Chat.count}</td>
                  <td>{this.props.metrics.Other.count}</td>
                  <td>{this.props.metrics.Repeat.count}</td>
                  <td>{this.props.metrics.Spam.count}</td>
                  <td>{this.props.metrics.Total.count}</td>
                  <td>{this.props.metrics.HighPotential.count}</td>
                  <td>{Math.round(this.props.metrics.RFQsSent.count/this.props.metrics.Total.count*10)/10}</td>
                  <td>{Math.round(this.props.metrics.RFQsSent.quotes/this.props.metrics.Total.count*1000)/10}%</td>
                  <td>{Math.round(this.props.metrics.RFQsFullMatch.count/this.props.metrics.Total.count*10)/10}</td>
                  <td>{Math.round(this.props.metrics.RFQsFullMatch.quotes/this.props.metrics.Total.count*1000)/10}%</td>
                  {/*
                  <td>{this.props.metrics.RFQsSent.count} 
                    &nbsp;({Math.round(this.props.metrics.RFQsSent.count/this.props.metrics.Total.count*10)/10}) / 
                    &nbsp;{this.props.metrics.RFQsSent.quotes} 
                    &nbsp;({Math.round(this.props.metrics.RFQsSent.quotes/this.props.metrics.Total.count*1000)/10}%)</td>
                  <td>{this.props.metrics.RFQsFullMatch.count} 
                    &nbsp;({Math.round(this.props.metrics.RFQsFullMatch.count/this.props.metrics.Total.count*10)/10}) /
                    &nbsp;{this.props.metrics.RFQsFullMatch.quotes}
                    &nbsp;({Math.round(this.props.metrics.RFQsFullMatch.quotes/this.props.metrics.Total.count*1000)/10}%)</td>
                    */}
                </tr>
          </tbody>
        </table>
    );
  }
}
