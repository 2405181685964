import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { matchQuotesAndData, matchQuotesAndEmails, matchQuotesAndLines, matchQuotesAndNotes, matchQuotesAndPOCosts } from '../../assets/functions/process/cam';
import { CSVLink } from 'react-csv';
import { matchQuotesAndNotesv2, matchQuotesAndPOs, matchQuotesAndRFQs } from '../../assets/functions/process/quotes';
import moment from 'moment';
import { CustomDateWrapper, Input } from '../styled/ui';

class SalesOperations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rep: 8,
            updateList: [],
        };

      }

      componentDidMount() {
          this.fetchData();
        }
      
        fetchData = async () => {
            let rep = this.state.rep
      
          try {
            const fetchFollowups = await getSQLData("sales_operations/rep_followups", {nRep: rep})
            const fetchQuotes = await getSQLData("sales_operations/rep_followup_quotes", {nRep: rep})
            const fetchQuoteLines = await getSQLData("sales_operations/rep_followup_quote_lines", {nRep: rep})
            const fetchPOs = await getSQLData("sales_operations/rep_followup_POs", {nRep: rep})
            const fetchRFQs = await getSQLData("sales_operations/rep_followup_RFQs", {nRep: rep})
            const fetchNotes = await getSQLData("sales_operations/rep_followup_notes", {nRep: rep})
            const fetchEmails = await getSQLData("sales_operations/rep_followup_emails", {nRep: rep})
            
            if(fetchFollowups && fetchQuotes && fetchQuoteLines && fetchPOs && fetchRFQs && fetchNotes && fetchEmails){
      
              let followups = fetchFollowups.data.data[0].recordset                        
              let quotes = fetchQuotes.data.data[0].recordset                        
              let quoteLines = fetchQuoteLines.data.data[0].recordset                        
              let POs = fetchPOs.data.data[0].recordset                        
              let RFQs = fetchRFQs.data.data[0].recordset                        
              let notes = fetchNotes.data.data[0].recordset                        
              let emails = fetchEmails.data.data[0].recordset                        
              
              this.setState({
                followups: followups,
                quotes: quotes,
                quoteLines: quoteLines,
                POs: POs,
                RFQs: RFQs,
                notes: notes,
                emails: emails,
              }, () => this.processData())
            }
               
          } catch (error) {
            console.error(error);
          }
        }
      
        processData() {
            let followups = this.state.followups
            let quotes = this.state.quotes
            let quoteLines = this.state.quoteLines
            let POs = this.state.POs
            let RFQs = this.state.RFQs
            let notes = this.state.notes
            let emails = this.state.emails
            
            quotes = matchQuotesAndLines(quotes, quoteLines)
            quotes = matchQuotesAndPOs({quotes: quotes, POs: POs}).quotes
            quotes = matchQuotesAndRFQs({quotes: quotes, RFQs: RFQs}).quotes
            quotes = matchQuotesAndNotesv2({quotes: quotes, notes: notes}).quotes
            quotes = matchQuotesAndEmails({quotes, emails}).quotes

            for(let i=0; i<quotes.length; i++){
                let followup = followups.find(x => x.nQuote===quotes[i].id)
                if(followup){
                    followup.quote = quotes[i]
                }
            }

            for(let i=0; i<followups.length; i++){
                followups[i].dtDate = moment(followups[i].dtDate).utc().format("YYYY-MM-DDTHH:mm")
            }

            let CSVData = []

            for(let i=0; i<followups.length; i++){
                CSVData.push({

                })
            }

            console.log(followups)
            
            this.setState({
                followups: followups,
                CSVData: CSVData,

                loaded: true,
            })
        }

        updateFollowup(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sNote = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sNote = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        updateCompleteNote(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sComplete = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sComplete = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        completeFollowup = async(index) => {
            let followups = this.state.followups
            let followup = followups[index]
            let leaveNote = false
            let nextFollowup = false

            if(followup.sNote) {
                leaveNote = true
            }

            if(followup.sNextFollowup){
                nextFollowup = true
            }

            try {
                const completeFollowup = await setSQLData("sales_operations/completeFollowup",{
                    id: followup.id,
                    nCompleted: 45,
                })

                if(leaveNote){
                    const leaveNote = await setSQLData("sales_operations/leaveNote",{
                        nRep: 45,
                        nQuote: followup.nQuote,
                        sNote: followup.sComplete,
                    })
                }

                if(nextFollowup){
                    const scheduleFollowup = await setSQLData("sales_operations/scheduleFollowup",{
                        nQuote: followups[index].nQuote,
                        nAdded: 45,
                        nRep: 8,
                        sNote: followups[index].sNextFollowup,
                        dtDate: moment(followups[index].dtNextDate).format("MM/DD/YYYY hh:mm A"),
                    })
                }

                
            } catch {

            }
        }

        saveFollowupChange = async (index) => {
            let followups = this.state.followups
            
            try {
                const updateFollowup = await setSQLData("sales_operations/updateFollowup",{
                    id: followups[index].id,
                    sNote: followups[index].sNote,
                    dtDate: moment(followups[index].dtDate).format("MM/DD/YYYY hh:mm A"),
                })

            } catch {

            }
        }

        updateData(index, value, type){
            let followups = this.state.followups
            let updateList = this.state.updateList

            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                switch(type){
                    case "date":
                        followups[index].dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateExists.dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        break;
                    case "followup":
                        followups[index].sNote = value
                        updateExists.sNote = value
                        break;
                    case "note":
                        followups[index].sComplete = value
                        updateExists.sComplete = value
                        break;
                    case "next date":
                        followups[index].dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateExists.dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        break;
                    case "next followup":
                        followups[index].sNextFollowup = value
                        updateExists.sNextFollowup = value
                        break;
                }
                
            } else {
                switch(type){
                    case "date":
                        followups[index].dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateList.push(followups[index])
                        break;
                    case "followup":
                        followups[index].sNote = value
                        updateList.push(followups[index])
                        break;
                    case "note":
                        followups[index].sComplete = value
                        updateList.push(followups[index])
                        break;
                    case "next date":
                        followups[index].dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateList.push(followups[index])
                        break;
                    case "next followup":
                        followups[index].sNextFollowup = value
                        updateList.push(followups[index])
                        break;
                }
                
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }
    render() {
        let followups = this.state.followups
        let CSVData = this.state.CSVData
        let updateList = this.state.updateList
        let loaded = this.state.loaded

        return (
            <div>
                {loaded && (

                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Quote</th>
                                <th>Customer</th>
                                <th>Contact</th>
                                <th>Followup</th>
                                <th>Complete Note</th>
                                <th>Next Followup</th>
                            </tr>
                        </thead>
                        {followups.map((followup,index)=>(
                            <tr>
                                <td>{followup.nQuote}</td>
                                <td>{followup.quote?.sCompany}<br />{followup.quote?.sName}</td>
                                <td>{followup.quote?.sPhone}<br />{followup.quote?.sEmail}</td>
                                <td>
                                    <CustomDateWrapper>
                                        <Input
                                            onChange={(e) => {
                                            this.updateData(index, e.target.value, "date");
                                            }}
                                            type="datetime-local"
                                            value={moment(followup.dtDate).format("YYYY-MM-DDTHH:mm")}
                                            style={{width:'200px',}}
                                        />
                                    </CustomDateWrapper>
                                            
                                    <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.updateData(index, e.target.value, "followup")} value={followup.sNote} />

                                    {updateList.some(x => x.id === followups[index].id) && <button onClick={()=>this.saveFollowupChange(index)}>Update</button>}

                                </td>
                                <td><textarea style={{width: '200px', height:'125px',}} onChange={(e)=>this.updateData(index, e.target.value, "note")} value={followup.sComplete} /></td>
                                
                                <td>
                                    <CustomDateWrapper>
                                            <Input
                                                onChange={(e) => {
                                                this.updateData(index, e.target.value, "next date");
                                                }}
                                                type="datetime-local"
                                                value={followup.dtNextDate ? moment(followup.dtNextDate).format("YYYY-MM-DDTHH:mm") : ""}
                                                style={{width:'200px',}}
                                            />
                                        </CustomDateWrapper>
                                <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.updateData(index, e.target.value, "next followup")} value={followup.sNextFollowup}/></td>
                                <td><button onClick={(e)=>this.completeFollowup(index)}>Complete</button></td>
                            </tr>
                        ))}
                    </table>
                </>
                )}
            </div>
        );
    }
}

export default SalesOperations;