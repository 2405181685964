import React, { Component } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';

import Home from './components/pages/Home';
import DesktopNavbar from './components/navbar/DesktopNavbar';
import MobileNavbar from './components/navbar/MobileNavbar';
import Login from './components/pages/Login';
import AdminDashboard from './components/pages/AdminDashboard';
import AccountingDashboard from './components/pages/AccountingDashboard';
import Results from './components/pages/Results';
import CamCsnDashboard from './components/pages/CamCsnDashboard';
import RacDashboard from './components/pages/RacDashboard';
import CamOperationsDashboard from './components/pages/CamOperationsDashboard';
import Settings from './components/pages/Settings';
import AllQuotes from './components/pages/AllQuotes';
import Quote from './components/pages/Quote';
import MasterViewer from './components/crm/master-viewer/index';
import MediaQuery from 'react-responsive';
import NewQuote from './components/pages/NewQuote';
import TASearch from './components/pages/TASearch';
import SupplierViewer from './components/srm';
import RacOperationsDashboard from './components/pages/RacOperationsDashboard';
import CustomQuery from './components/pages/CustomQuery';
import Tutorial from './components/pages/Tutorial';
import TierWizard from './components/pages/TierWizard';
import { fetchUser } from './assets/functions/user';
import RacLogisticsHomev2 from './components/pages/home/RacLogisticsHomev2';
import UnderwritingDashboard from './components/pages/UnderwritingDashboard';
import MarketingDashboard from './components/pages/MarketingDashboard';
import SendEmail from './components/email/SendEmail';
import CannedResponses from './components/email/CannedResponses';
import AdminDashboardv2 from './components/pages/AdminDashboardv2';
import NewLeadReview from './components/pages/NewLeadReview';
import TakeNewQuote from './components/pages/TakeNewQuote';
import DiscontinuedQuoteReview from './components/pages/DiscontinuedQuoteReview';
import AccountingDashboardv2 from './components/pages/AccountingDashboardv2';
import SalesOperations from './components/pages/SalesOperations';
import SalesOperationsv2 from './components/pages/SalesOperationsv2';
import FollowupEditor from './components/pages/FollowupEditor';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Command Center",
      user: null,
      userLoaded: false,
    };
    this.updateTitleHandler = this.updateTitleHandler.bind(this);
  }

  updateTitleHandler(title) {
    this.setState({ title });
  }

  async componentDidMount() {
    try {
      const data = await fetchUser();
      if (data) {
        console.log("Fetched user data:", data);
        this.setState({
          user: data.user,  
          permissions: data.permissions,
          userLoaded: true,
        });
        
        
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
  
  render() {
    const { user, permissions, title, userLoaded } = this.state;

    return (
      <Router>
        {!userLoaded && <Route><Login /></Route>}

        {userLoaded && user && (
          <>
            <DesktopNavbar
              user={user}
              permissions={permissions}
              title={title}
              updateTitle={this.updateTitleHandler}
              location={window.location.hash}
            />
            <Switch>
              <Route exact path="/">
                <Home user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/home">
                <Home user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/new-quote">
                <NewQuote user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/ta-search">
                <TASearch user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/admin-dashboard">
                <AdminDashboardv2 user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/accounting-dashboard">
                <AccountingDashboard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/cam-csn-dashboard">
                <CamCsnDashboard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/cam-operations">
                <CamOperationsDashboard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/rac-dashboard">
                <RacDashboard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/rac-operations">
                <RacOperationsDashboard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/sales-operations">
                <SalesOperationsv2 user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/followup-editor">
                <FollowupEditor user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/all-quotes">
                <AllQuotes user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/custom-query">
                <CustomQuery user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/settings">
                <Settings user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/tutorial">
                <Tutorial user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/logistics-dashboard">
                <RacLogisticsHomev2 user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/underwriting-dashboard">
                <UnderwritingDashboard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/marketing-dashboard">
                <MarketingDashboard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/send-email">
                <SendEmail user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/canned-responses">
                <CannedResponses user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/marketing-tier-wizard">
                <TierWizard user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/new-lead-review">
                <NewLeadReview user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/take-new-quote">
                <TakeNewQuote user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/discontinued-quote-review">
                <DiscontinuedQuoteReview user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
              <Route exact path="/crm/master-viewer/:customer" component={MasterViewer} />
              <Route exact path="/srm/master-viewer/:supplier" component={SupplierViewer} />
              <Route exact path="/quote/:id" component={Quote} />
              <Route exact path="/results/:search">
                <Results user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>

              <Route path="*">
                <Home user={user} updateTitle={this.updateTitleHandler} permissions={permissions} />
              </Route>
            </Switch>
          </>
        )}
      </Router>
    );
  }
}

export default App;
