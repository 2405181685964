import React, { Component } from 'react';
import { getSQLData } from '../../../assets/functions/fetch';
import moment from 'moment';
import { nQuoteTotalNoDollar, whichRep } from '../../../assets/functions/calculations';
import { returnLikelihood, returnPotentialRange } from '../../../assets/functions/process/quotes';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class SalesReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            viewing: "New",
        };

      }
    
      fetchData = async () => {
    
        try {
          const fetchNew = await getSQLData("cam/newQuotesSalesReport", {})
          const fetchOpen = await getSQLData("cam/openQuotesSalesReport", {})
          const fetchClosed = await getSQLData("cam/closedQuotesSalesReport", {})
          const fetchDiscontinued = await getSQLData("cam/discontinuedQuotesSalesReport", {})
          const fetchSent = await getSQLData("cam/sentQuoteSalesReport", {})
          const fetchActivity = await getSQLData("cam/activityLogSalesReport", {})
          
          if(fetchNew && fetchOpen && fetchClosed && fetchDiscontinued && fetchSent && fetchActivity){
    
            let newQuotes = fetchNew.data.data[0].recordset    
            let openQuotes = fetchOpen.data.data[0].recordset    
            let closedQuotes = fetchClosed.data.data[0].recordset    
            let discontinuedQuotes = fetchDiscontinued.data.data[0].recordset    
            let sentQuotes = fetchSent.data.data[0].recordset    
            let activityLog = fetchActivity.data.data[0].recordset    
            
            this.setState({
                newQuotes: newQuotes,
                openQuotes: openQuotes,
                closedQuotes: closedQuotes,
                discontinuedQuotes: discontinuedQuotes,
                sentQuotes: sentQuotes,
                activityLog: activityLog,
            }, () => this.processData())
          }
             
        } catch (error) {
          console.error(error);
        }
      }
    
      processData(){
    
        
        this.setState({
            loaded: true,
        })
      }
    
      componentDidMount() {
        this.fetchData();
      }

    render() {
        let viewing = this.state.viewing
        let loaded = this.state.loaded
        let newQuotes = this.state.newQuotes
        let openQuotes = this.state.openQuotes
        let closedQuotes = this.state.closedQuotes
        let discontinuedQuotes = this.state.discontinuedQuotes
        let sentQuotes = this.state.sentQuotes
        let activityLog = this.state.activityLog

        return (
            <>
            {loaded && (
                <>
                <div>
                    <button className='camBtn' onClick={()=>this.setState({viewing: "New"})} style={{fontWeight: viewing === "New" ? "bold" : ""}}>New ({newQuotes.length})</button>
                    <button className='camBtn' onClick={()=>this.setState({viewing: "Open"})} style={{fontWeight: viewing === "Open" ? "bold" : ""}}>Open ({openQuotes.length})</button>
                    <button className='camBtn' onClick={()=>this.setState({viewing: "Closed"})} style={{fontWeight: viewing === "Closed" ? "bold" : ""}}>Closed ({closedQuotes.length})</button>
                    <button className='camBtn' onClick={()=>this.setState({viewing: "Discontinued"})} style={{fontWeight: viewing === "Discontinued" ? "bold" : ""}}>Discontinued ({discontinuedQuotes.length})</button>
                    <button className='camBtn' onClick={()=>this.setState({viewing: "Sent"})} style={{fontWeight: viewing === "Sent" ? "bold" : ""}}>Sent ({sentQuotes.length})</button>
                    <button className='camBtn' onClick={()=>this.setState({viewing: "Activity"})} style={{fontWeight: viewing === "Activity" ? "bold" : ""}}>Activity ({activityLog.length})</button>
                </div>
            
            {viewing === "New" && (
                <table className="dashboard">
                <thead>
                  <tr>
                    <th>Quote</th>
                    <th>Rep</th>
                    <th>Customer</th>
                    <th>Location</th>
                    <th>Date Created</th>
                    <th>Date Sent</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Rank</th>
                    <th>Likelihood</th>
                    <th>Marketing</th>
                  </tr>
                </thead>
                {newQuotes.map((quote,index)=>(
                    <tr>
                        <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                        <td>{whichRep(quote.nRep)}</td>
                        <td>{quote.sCompany} <br /> {quote.sName}</td>
                        <td>{quote.sCity}, {quote.sState} {quote.sZip} <br />{quote.sDCity}, {quote.sDState} {quote.sDZip}</td>
                        <td>{moment(quote.dtDateStamp).format("MM/DD/YY")}</td>
                        <td>{moment(quote.dtSentToCustomer).format("MM/DD/YY")}</td>
                        <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                        <td>{quote.sStatus}</td>
                        <td>{returnPotentialRange(quote.nPotential)}</td>
                        <td>{returnLikelihood(quote.nLikelyToClose)}</td>
                        <td>{quote.sType}<br />
                        {quote.sLeadSource} {quote.sCampaign}</td>
                    </tr>
                ))}
                </table>
            )}
            {viewing === "Open" && (
                <table className="dashboard">
                <thead>
                  <tr>
                    <th>Quote</th>
                    <th>Rep</th>
                    <th>Customer</th>
                    <th>Location</th>
                    <th>Date Created</th>
                    <th>Date Sent</th>
                    <th>Price</th>
                    <th>Rank</th>
                    <th>Likelihood</th>
                    <th>Marketing</th>
                  </tr>
                </thead>
                {openQuotes.map((quote,index)=>(
                    <tr>
                        <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                        <td>{whichRep(quote.nRep)}</td>
                        <td>{quote.sCompany} <br /> {quote.sName}</td>
                        <td>{quote.sCity}, {quote.sState} {quote.sZip} <br />{quote.sDCity}, {quote.sDState} {quote.sDZip}</td>
                        <td>{moment(quote.dtDateStamp).format("MM/DD/YY")}</td>
                        <td>{moment(quote.dtSentToCustomer).format("MM/DD/YY")}</td>
                        <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                        <td>{returnPotentialRange(quote.nPotential)}</td>
                        <td>{returnLikelihood(quote.nLikelyToClose)}</td>
                        <td>{quote.sType}<br />
                        {quote.sLeadSource} {quote.sCampaign}</td>
                    </tr>
                ))}
                </table>
            )}
            {viewing === "Closed" && (
                <table className="dashboard">
                <thead>
                  <tr>
                    <th>Quote</th>
                    <th>Rep</th>
                    <th>Customer</th>
                    <th>Location</th>
                    <th>Date Created</th>
                    <th>Date Closed</th>
                    <th>Price</th>
                    <th>Rank</th>
                    <th>Likelihood</th>
                    <th>Marketing</th>
                  </tr>
                </thead>
                {closedQuotes.map((quote,index)=>(
                    <tr>
                        <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                        <td>{whichRep(quote.nRep)}</td>
                        <td>{quote.sCompany} <br /> {quote.sName}</td>
                        <td>{quote.sCity}, {quote.sState} {quote.sZip} <br />{quote.sDCity}, {quote.sDState} {quote.sDZip}</td>
                        <td>{moment(quote.dtDateStamp).format("MM/DD/YY")}</td>
                        <td>{moment(quote.dtFirstClosed).format("MM/DD/YY")}</td>
                        <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                        <td>{returnPotentialRange(quote.nPotential)}</td>
                        <td>{returnLikelihood(quote.nLikelyToClose)}</td>
                        <td>{quote.sType}<br />
                        {quote.sLeadSource} {quote.sCampaign}</td>
                    </tr>
                ))}
                </table>
            )}
            {viewing === "Discontinued" && (
                <table className="dashboard">
                <thead>
                  <tr>
                    <th>Quote</th>
                    <th>Rep</th>
                    <th>Customer</th>
                    <th>Location</th>
                    <th>Date Created</th>
                    <th>Date Discontinued</th>
                    <th>Price</th>
                    <th>Rank</th>
                    <th>Likelihood</th>
                    <th>Marketing</th>
                  </tr>
                </thead>
                {discontinuedQuotes.map((quote,index)=>(
                    <tr>
                        <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                        <td>{whichRep(quote.nRep)}</td>
                        <td>{quote.sCompany} <br /> {quote.sName}</td>
                        <td>{quote.sCity}, {quote.sState} {quote.sZip} <br />{quote.sDCity}, {quote.sDState} {quote.sDZip}</td>
                        <td>{moment(quote.dtDateStamp).format("MM/DD/YY")}</td>
                        <td>{moment(quote.dtLastUpdated).format("MM/DD/YY")}</td>
                        <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                        <td>{returnPotentialRange(quote.nPotential)}</td>
                        <td>{returnLikelihood(quote.nLikelyToClose)}</td>
                        <td>{quote.sType}<br />
                        {quote.sLeadSource} {quote.sCampaign}</td>
                    </tr>
                ))}
                </table>
            )}
            {viewing === "Sent" && (
                <table className="dashboard">
                <thead>
                  <tr>
                    <th>Quote</th>
                    <th>Rep</th>
                    <th>Customer</th>
                    <th>Location</th>
                    <th>Date Created</th>
                    <th>Date Sent</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Rank</th>
                    <th>Likelihood</th>
                    <th>Marketing</th>
                  </tr>
                </thead>
                {sentQuotes.map((quote,index)=>(
                    <tr>
                        <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                        <td>{whichRep(quote.nRep)}</td>
                        <td>{quote.sCompany} <br /> {quote.sName}</td>
                        <td>{quote.sCity}, {quote.sState} {quote.sZip} <br />{quote.sDCity}, {quote.sDState} {quote.sDZip}</td>
                        <td>{moment(quote.dtDateStamp).format("MM/DD/YY")}</td>
                        <td>{moment(quote.dtSentToCustomer).format("MM/DD/YY")}</td>
                        <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                        <td>{quote.sStatus}</td>
                        <td>{returnPotentialRange(quote.nPotential)}</td>
                        <td>{returnLikelihood(quote.nLikelyToClose)}</td>
                        <td>{quote.sType}<br />
                        {quote.sLeadSource} {quote.sCampaign}</td>
                    </tr>
                ))}
                </table>
            )}
            {viewing === "Activity" && (
                <table className="dashboard">
                <thead>
                  <tr>
                    <th>Quote</th>
                    <th>Rep</th>
                    <th>Customer</th>
                    <th>Location</th>
                    <th>Date Created</th>
                    <th>Activity Date</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Rank</th>
                    <th>Likelihood</th>
                    <th>Marketing</th>
                  </tr>
                </thead>
                {activityLog.map((quote,index)=>(
                    <tr>
                        <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                        <td>{whichRep(quote.nRep)}</td>
                        <td>{quote.sCompany} <br /> {quote.sName}</td>
                        <td>{quote.sCity}, {quote.sState} {quote.sZip} <br />{quote.sDCity}, {quote.sDState} {quote.sDZip}</td>
                        <td>{moment(quote.dtDateStamp).format("MM/DD/YY")}</td>
                        <td>{moment(quote.dtWhen).format("MM/DD/YY")}</td>
                        <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                        <td>{quote.sStatus}</td>
                        <td>{returnPotentialRange(quote.nPotential)}</td>
                        <td>{returnLikelihood(quote.nLikelyToClose)}</td>
                        <td>{quote.sType}<br />
                        {quote.sLeadSource} {quote.sCampaign}</td>
                    </tr>
                ))}
                </table>
            )}
            
            </>
            )}
                
            </>
        );
    }
}

export default SalesReport;