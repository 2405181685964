import moment from "moment"
import { weekStart } from "../time"
import { camStatsTemplate } from "../system"
import { CAMTargets } from "../../data/targets"
import { activeCoordinators, activeInstallers } from "../calculations"

export function returnSiteVisitArrayName(viewing){
  let arrayName = ""
  switch (viewing){
    case "Site Visit Needs Scheduled":
      arrayName = "needScheduled"
      break;
    case "Site Visit Has Planned Date":
      arrayName = "needSiteVisit"
      break;
    case "Quote Needs Sent":
      arrayName = "needQuoteSent"
      break;
    case "New CAM Quotes":
      arrayName = "newQuotes"
      break;
    case "Recent CAM Quotes":
      arrayName = "lastMonthQuotes"
      break;
    case "Old CAM Quotes":
      arrayName = "oldQuotes"
      break;
    case "Discontinued CAM Quotes":
      arrayName = "discontinuedQuotes"
      break;
    default:
  }
  return arrayName
}

export function processSiteVisitDatav2(data){
  let siteVisits = {}
  siteVisits.needScheduled = []
  siteVisits.needSiteVisit = []
  siteVisits.needQuoteSent = []
  siteVisits.newQuotes = []
  siteVisits.lastMonthQuotes = []
  siteVisits.oldQuotes = []
  siteVisits.discontinuedQuotes = []
  
  for(let i=0; i<data.length; i++){
    if(data[i].dtDateStamp){
      data[i].Age = moment(moment()).diff(data[i].dtDateStamp,"day")
    }

    if(data[i].sStatus === "Discontinued"){
      siteVisits.discontinuedQuotes.push(data[i])
        continue
    }

    if(!data[i].bNeedSiteVisit){
      if(data[i].Age <= 7){
        siteVisits.newQuotes.push(data[i])
        continue
      }
      if(data[i].Age <= 31){
        siteVisits.lastMonthQuotes.push(data[i])
        continue
      }
      siteVisits.oldQuotes.push(data[i])
      continue
    }

    if(!data[i].dtSiteVisitPlanned){
      siteVisits.needScheduled.push(data[i])
      continue
    }
    if(!data[i].dtSiteVisit){
      siteVisits.needSiteVisit.push(data[i])
      continue
    }
    siteVisits.needQuoteSent.push(data[i])

  }

  return siteVisits
}

export function processSiteVisitData(data){
    data.needSiteVisit = []
    data.needSiteVisitNoDate = []
    data.needSiteVisitHasDate = []
    data.siteVisitComplete = []
    data.siteVisitCompleteNoQuote = []
    data.siteVisitCompleteNeedsSent = []
    data.quoteSent = []
    data.quoteSentClosed = []
    data.quoteSentDiscontinued = []
    data.quoteSentOpen = []

    for(let i=0; i<data.length; i++){
      if(data[i].dtDateStamp){
        data[i].Age = moment(moment()).diff(data[i].dtDateStamp,"day")
      }

      if(data[i].dtSiteVisitPlanned){
        data[i].DaysUntilSiteVisit = moment(data[i].dtSiteVisitPlanned).diff(moment(),"day")
        if(data[i].dtDateStamp){data[i].SiteVisitPlannedLead = moment(data[i].dtSiteVisitPlanned).diff(data[i].dtDateStamp,"day")}
      }

      if(data[i].dtSiteVisit){
        data[i].DaysSinceSiteVisit = moment().diff(data[i].dtSiteVisit,"day")
        if(data[i].dtDateStamp){data[i].SiteVisitActualLead = moment(data[i].dtSiteVisit).diff(data[i].dtDateStamp,"day")}

        data.siteVisitComplete.push(data[i])
        if(!data[i].dtSentDate){
          data.siteVisitCompleteNoQuote.push(data[i])
          if(data[i].sStatus !== "Discontinued"){
            data.siteVisitCompleteNeedsSent.push(data[i])
          }
        }
      }

      if(data[i].dtSentDate){
        data[i].DaysSinceQuoteSent = moment().diff(data[i].dtSentDate,"day")
        if(data[i].dtSentDate){data[i].DaysFromSiteVisitToSent = moment(data[i].dtSentDate).diff(data[i].dtSiteVisit,"day")}
        if(data[i].dtDateStamp){data[i].TotalLeadTime = moment(data[i].dtSentDate).diff(data[i].dtDateStamp,"day")}

        data.quoteSent.push(data[i])
        if(data[i].sStatus == "Closed"){data.quoteSentClosed.push(data[i])}
        if(data[i].sStatus == "Open"){data.quoteSentOpen.push(data[i])}
        if(data[i].sStatus == "Discontinued"){data.quoteSentDiscontinued.push(data[i])}
      }

      if(data[i].bNeedSiteVisit){
        data.needSiteVisit.push(data[i])

        if(data[i].dtSiteVisitPlanned){
          data.needSiteVisitHasDate.push(data[i])
        } else {
          data.needSiteVisitNoDate.push(data[i])
        }

      }
    }

  data.needSiteVisitNoDate.sort((a,b)=>(moment(a.dtDateStamp).diff(moment(b.dtDateStamp))>0) ? 1 : (moment(a.dtDateStamp).diff(moment(b.dtDateStamp))<0) ? -1 : 0)
  data.needSiteVisitHasDate.sort((a,b)=>(moment(a.dtSiteVisitPlanned).diff(moment(b.dtSiteVisitPlanned))>0) ? 1 : (moment(a.dtSiteVisitPlanned).diff(moment(b.dtSiteVisitPlanned))<0) ? -1 : 0)
  data.siteVisitCompleteNeedsSent.sort((a,b)=>(moment(a.dtSiteVisit).diff(moment(b.dtSiteVisit))>0) ? 1 : (moment(a.dtSiteVisit).diff(moment(b.dtSiteVisit))<0) ? -1 : 0)
  data.quoteSentOpen.sort((a,b)=>(moment(a.dtSentDate).diff(moment(b.dtSentDate))>0) ? 1 : (moment(a.dtSentDate).diff(moment(b.dtSentDate))<0) ? -1 : 0)
  data.quoteSentDiscontinued.sort((a,b)=>(moment(a.dtLastMove).diff(moment(b.dtLastMove))>0) ? 1 : (moment(a.dtLastMove).diff(moment(b.dtLastMove))<0) ? -1 : 0)

   return data
}


export function organizeChartData(months){
  let chartData = {}
  chartData.labels = []
  chartData.data = []
  let years = months[0].years.length
  for(let i=0; i<years; i++){
    chartData.data.push([])
  }
  chartData.target = []

  console.log(months)

  for(let i=0; i<months.length; i++){
    chartData.labels.push(moment(months[i].years[0].month).format("MM/YY"))
    for(let j=0; j<years; j++){
      chartData.data[j].push(months[i].years[j].completed.margin.all+months[i].years[j].completed.labor)
    }
    chartData.target.push(months[i].targetMargin[0])
  }

  chartData.lowerBound = 0
  chartData.upperBound = Math.max(...chartData.target)
  for(let i=0; i<years; i++){
    chartData.upperBound = Math.max(...chartData.data[i], chartData.upperBound)
  }

  let zeroes = Math.floor(Math.log10(chartData.upperBound))-1
  chartData.upperBound = Math.ceil(chartData.upperBound/Math.pow(10,zeroes))*Math.pow(10,zeroes)

  chartData.labels.reverse()
  chartData.data[0].reverse()
  chartData.data[1].reverse()
  chartData.target.reverse()

  return chartData
}

export function summarizeMonthsAndYears(data){
  let numMonths = data.length
  let numYears = Math.floor((numMonths-2)/12+1)
  let months = []
  months.yearNums = []
  months.total = []
  

  for(let i=0; i<numYears; i++){
    months.total.push(camStatsTemplate())
    for(let j=0; j<12; j++){
      let index = j + i*12
      let year = moment(data[index].month).format('YYYY')
      let month = moment(data[index].month).format('MM')
      let targetSales = CAMTargets.sales[year][month-1]
      let targetMargin = CAMTargets.margin[year][month-1]

      if(i===0){
        months[j]={
          years: [],
          yearNums: [],
          targetSales: [],
          targetMargin: []    
        }
        
      }
      months[j].years.push(data[index])
      months[j].yearNums.push(year)
      months[j].targetSales.push(targetSales)
      months[j].targetMargin.push(targetMargin)
      
      months.total[i].closed.count.all += months[j].years[i].closed.count.all
      months.total[i].closed.count.csn += months[j].years[i].closed.count.csn
      months.total[i].closed.count.contract += months[j].years[i].closed.count.contract
      months.total[i].closed.sales.all += months[j].years[i].closed.sales.all
      months.total[i].closed.sales.csn += months[j].years[i].closed.sales.csn
      months.total[i].closed.sales.contract += months[j].years[i].closed.sales.contract
      months.total[i].closed.margin.all += months[j].years[i].closed.margin.all
      months.total[i].closed.margin.csn += months[j].years[i].closed.margin.csn
      months.total[i].closed.margin.contract += months[j].years[i].closed.margin.contract
      months.total[i].closed.labor += months[j].years[i].closed.labor
      months.total[i].completed.count.all += months[j].years[i].completed.count.all
      months.total[i].completed.count.csn += months[j].years[i].completed.count.csn
      months.total[i].completed.count.contract += months[j].years[i].completed.count.contract
      months.total[i].completed.sales.all += months[j].years[i].completed.sales.all
      months.total[i].completed.sales.csn += months[j].years[i].completed.sales.csn
      months.total[i].completed.sales.contract += months[j].years[i].completed.sales.contract
      months.total[i].completed.margin.all += months[j].years[i].completed.margin.all
      months.total[i].completed.margin.csn += months[j].years[i].completed.margin.csn
      months.total[i].completed.margin.contract += months[j].years[i].completed.margin.contract
      months.total[i].completed.labor += months[j].years[i].completed.labor
      months.total[i].target.sales += targetSales
      months.total[i].target.margin += targetMargin
    }
    
  }
  return compareYears(months)
}

export function compareYears(months){
  for(let i=0; i<months.length; i++){
    let numYears = months[i].years.length
    months[i].comps = []
    months[i].compsNum = []
    if(numYears > 1){
      for(let j=0; j<numYears-2; j++){
        months[i].compsNum[j] = {
          closed: {
            count: {
              all: months[i].years[0].closed.count.all-months[i].years[j+1].closed.count.all,
              csn: months[i].years[0].closed.count.csn-months[i].years[j+1].closed.count.csn,
              contract: months[i].years[0].closed.count.contract-months[i].years[j+1].closed.count.contract,
            },
            sales: {
              all: months[i].years[0].closed.sales.all-months[i].years[j+1].closed.sales.all,
              csn: months[i].years[0].closed.sales.csn-months[i].years[j+1].closed.sales.csn,
              contract: months[i].years[0].closed.sales.contract-months[i].years[j+1].closed.sales.contract,
            },
            margin: {
              all: months[i].years[0].closed.margin.all-months[i].years[j+1].closed.margin.all,
              csn: months[i].years[0].closed.margin.csn-months[i].years[j+1].closed.margin.csn,
              contract: months[i].years[0].closed.margin.contract-months[i].years[j+1].closed.margin.contract,
            },
          },
          completed: {
            count: {
              all: months[i].years[0].completed.count.all-months[i].years[j+1].completed.count.all,
              csn: months[i].years[0].completed.count.csn-months[i].years[j+1].completed.count.csn,
              contract: months[i].years[0].completed.count.contract-months[i].years[j+1].completed.count.contract,
            },
            sales: {
              all: months[i].years[0].completed.sales.all-months[i].years[j+1].completed.sales.all,
              csn: months[i].years[0].completed.sales.csn-months[i].years[j+1].completed.sales.csn,
              contract: months[i].years[0].completed.sales.contract-months[i].years[j+1].completed.sales.contract,
            },
            margin: {
              all: months[i].years[0].completed.margin.all-months[i].years[j+1].completed.margin.all,
              csn: months[i].years[0].completed.margin.csn-months[i].years[j+1].completed.margin.csn,
              contract: months[i].years[0].completed.margin.contract-months[i].years[j+1].completed.margin.contract,
            },
          },
        }
        months[i].comps[j] = {
          closed: {
            count: {
              all: months[i].compsNum[j].closed.count.all/months[i].years[0].closed.count.all,
              csn: months[i].compsNum[j].closed.count.csn/months[i].years[0].closed.count.csn,
              contract: months[i].compsNum[j].closed.count.contract/months[i].years[0].closed.count.contract,
            },
            sales: {
              all: months[i].compsNum[j].closed.sales.all/months[i].years[0].closed.sales.all,
              csn: months[i].compsNum[j].closed.sales.csn/months[i].years[0].closed.sales.csn,
              contract: months[i].compsNum[j].closed.sales.contract/months[i].years[0].closed.sales.contract,
            },
            margin: {
              all: months[i].compsNum[j].closed.margin.all/months[i].years[0].closed.margin.all,
              csn: months[i].compsNum[j].closed.margin.csn/months[i].years[0].closed.margin.csn,
              contract: months[i].compsNum[j].closed.margin.contract/months[i].years[0].closed.margin.contract,
            },
          },
          completed: {
            count: {
              all: months[i].compsNum[j].completed.count.all/months[i].years[0].completed.count.all,
              csn: months[i].compsNum[j].completed.count.csn/months[i].years[0].completed.count.csn,
              contract: months[i].compsNum[j].completed.count.contract/months[i].years[0].completed.count.contract,
            },
            sales: {
              all: months[i].compsNum[j].completed.sales.all/months[i].years[0].completed.sales.all,
              csn: months[i].compsNum[j].completed.sales.csn/months[i].years[0].completed.sales.csn,
              contract: months[i].compsNum[j].completed.sales.contract/months[i].years[0].completed.sales.contract,
            },
            margin: {
              all: months[i].compsNum[j].completed.margin.all/months[i].years[0].completed.margin.all,
              csn: months[i].compsNum[j].completed.margin.csn/months[i].years[0].completed.margin.csn,
              contract: months[i].compsNum[j].completed.margin.contract/months[i].years[0].completed.margin.contract,
            },
          }
        }
      }
    }
  }

  return months
}
export function matchClosedAndCompleteToWeeks(data){
  let weeks = []
  let today = new Date()
  let numWeeks = 110
  let numWeeksYear = 52
  let maxReps = 100
  let margin = []
  let sales = []
  let targetSales = []
  let targetMargin = []
  let targetLabor = []
  let labor = []
  let numQuotes = []
  let movingAverageSales = []
  let movingAverageMargin = []
  let movingAverageLabor = []
  let weekQuotes = []

  for(let i=0; i<numWeeks; i++){
    weeks[i] = weekStart(6+i*7).slice(0,5)
    margin[i] = 0
    sales[i] = 0
    targetSales[i] = 40000
    targetMargin[i] = 16000
    targetLabor[i] = 4000
    labor[i] = 0
    movingAverageSales[i] = 0
    movingAverageMargin[i] = 0
    movingAverageLabor[i] = 0
    weekQuotes[i] = []
  }

  for(let i=0; i<data.length; i++){
    let week = Math.floor(Math.floor((moment(weekStart(0)) - moment(data[i].dtComplete))/1000/24/3600)/7)
    if(week<numWeeks){
      numQuotes[week] += 1
      margin[week] += data[i].TotalPrice + data[i].nDelivery - data[i].POTotal + data[i].Labor
      sales[week] += data[i].TotalPrice + data[i].nDelivery
      labor[week] += data[i].Labor
      weekQuotes[week].push(data[i])
    }
  }

  for(let i=0; i<numWeeks-4; i++){
    movingAverageSales[i] = (sales[i] + sales[i+1] + sales[i+2] + sales[i+3])/4
    movingAverageMargin[i] = (margin[i] + margin[i+1] + margin[i+2] + margin[i+3])/4
    movingAverageLabor[i] = (labor[i] + labor[i+1] + labor[i+2] + labor[i+3])/4
  }

  let topMargin = Math.max(...margin)
 
  let zeros = Math.floor(Math.log10(topMargin))
  let upperBoundMargin = Math.ceil(topMargin / Math.pow(10, zeros)) * Math.pow(10, zeros)


  let topSales = Math.max(...sales)
  zeros = Math.floor(Math.log10(topSales))
  let upperBoundSales = Math.ceil(topSales / Math.pow(10, zeros)) * Math.pow(10, zeros)

  let topLabor = Math.max(...labor)
  zeros = Math.floor(Math.log10(topLabor))
  let upperBoundLabor = Math.ceil(topLabor / Math.pow(10, zeros)) * Math.pow(10, zeros)


  let summaryData = {
    weeks: weeks,
    sales: sales,
    margin: margin,
    labor: labor,
    movingAverageSales: movingAverageSales,
    movingAverageMargin: movingAverageMargin,
    movingAverageLabor: movingAverageLabor,
    targetSales: targetSales,
    targetMargin: targetMargin,
    targetLabor: targetLabor,
    upperBoundMargin: upperBoundMargin,
    upperBoundSales: upperBoundSales,
    upperBoundLabor: upperBoundLabor,
    weekQuotes: weekQuotes,
  }

  return summaryData
}

export function matchClosedAndCompleteToMonths(data, numMonths){
  let months = []
  for(let i=0; i<numMonths; i++){
    months[i] = {
      month: moment().subtract(i, 'months').startOf('month').utc('MM/DD/YYYY'),
      closed: {
        all: [],
        csn: [],
        contract: [],
        count: {
          all: 0,
          csn: 0,
          contract: 0,
        },
        sales: {
          all: 0,
          csn: 0,
          contract: 0,
        },
        margin: {
          all: 0,
          csn: 0,
          contract: 0,
        },
        labor: 0,
      },
      completed: {
        all: [],
        csn: [],
        contract: [],
        count: {
          all: 0,
          csn: 0,
          contract: 0,
        },
        sales: {
          all: 0,
          csn: 0,
          contract: 0,
        },
        margin: {
          all: 0,
          csn: 0,
          contract: 0,
        },
        labor: 0,
      },
    }
  }
  for(let i=0; i<data.length; i++){
    let monthsAgoClosed = moment().startOf('month').diff(moment(data[i].dtFirstClosed).startOf('month'),'months')
    let monthsAgoComplete = moment().startOf('month').diff(moment(data[i].dtComplete).startOf('month'),'months')
    
    if(monthsAgoClosed >= 0 && monthsAgoClosed <= 24){
      months[monthsAgoClosed].closed.all.push(data[i])
      months[monthsAgoClosed].closed.count.all++
      months[monthsAgoClosed].closed.sales.all += data[i].nQuoteTotal
      months[monthsAgoClosed].closed.margin.all += parseFloat(data[i].nQuoteTotal - data[i].POTotal)
      if(data[i].Labor > 0){
        months[monthsAgoClosed].closed.csn.push(data[i])
        months[monthsAgoClosed].closed.count.csn++
        months[monthsAgoClosed].closed.sales.csn += data[i].nQuoteTotal
        months[monthsAgoClosed].closed.margin.csn += parseFloat(data[i].nQuoteTotal - data[i].POTotal)
        months[monthsAgoClosed].closed.labor += data[i].Labor
      } else {
        months[monthsAgoClosed].closed.contract.push(data[i])
        months[monthsAgoClosed].closed.count.contract++
        months[monthsAgoClosed].closed.sales.contract += data[i].nQuoteTotal
        months[monthsAgoClosed].closed.margin.contract += parseFloat(data[i].nQuoteTotal - data[i].POTotal)
      }
    }
    if(monthsAgoComplete >= 0 && monthsAgoComplete <= 24){
      months[monthsAgoComplete].completed.all.push(data[i])
      months[monthsAgoComplete].completed.count.all++
      months[monthsAgoComplete].completed.sales.all += data[i].nQuoteTotal
      months[monthsAgoComplete].completed.margin.all += parseFloat(data[i].nQuoteTotal - data[i].POTotal)
      if(data[i].Labor > 0){
        months[monthsAgoComplete].completed.csn.push(data[i])
        months[monthsAgoComplete].completed.count.csn++
        months[monthsAgoComplete].completed.sales.csn += data[i].nQuoteTotal
        months[monthsAgoComplete].completed.margin.csn += parseFloat(data[i].nQuoteTotal - data[i].POTotal)
        months[monthsAgoComplete].completed.labor += data[i].Labor
      } else {
        months[monthsAgoComplete].completed.contract.push(data[i])
        months[monthsAgoComplete].completed.count.contract++
        months[monthsAgoComplete].completed.sales.contract += data[i].nQuoteTotal
        months[monthsAgoComplete].completed.margin.contract += parseFloat(data[i].nQuoteTotal - data[i].POTotal)
      }
    }

  }
  return months
}

export function matchQuotesAndNotes(data, notes){
  if(data && notes){
    for(let i=0; i<data.length; i++){
      data[i].notes = []
    }
  
    for(let i=0; i<notes.length; i++){
      if(notes[i].nRep > 0){
        let quoteNum = notes[i].nQuote
        if(quoteNum){
          let quote = data.find(x => x.id===quoteNum)
          if(quote){
            quote.notes.push(notes[i])
          }
        }
      }
    }
  }
  return data
}
export function matchQuotesAndFollowups(data, followups){
  if(data && followups){
    for(let i=0; i<data.length; i++){
      data[i].followups = []
    }
  
    for(let i=0; i<followups.length; i++){
        let quoteNum = followups[i].nQuote
        if(quoteNum){
          let quote = data.find(x => x.id===quoteNum)
          if(quote){
            quote.followups.push(followups[i])
          }
        }
    }
  }
  return data
}
  export function matchQuotesAndContacts(data, contacts){
    if(data && contacts){
      for(let i=0; i<data.length; i++){
        data[i].contacts = []
      }
    
      for(let i=0; i<contacts.length; i++){
        if(contacts[i].nCompany > 0){
          let companyID = contacts[i].nCompany
          if(companyID){
            let quote = data.find(x => x.nCompany===companyID)
            if(quote){
              quote.contacts.push(contacts[i])
            }
          }
        }
      }
    }
  
    return data
  }
export function matchQuotesAndEmails(data, emails){
  if(data && emails){
    for(let i=0; i<data.length; i++){
      data[i].emails = []
    }
  
    for(let i=0; i<emails.length; i++){
        let quoteNum = emails[i].nQuote
        if(quoteNum){
          let quote = data.find(x => x.id===quoteNum)
          if(quote){
            quote.emails.push(emails[i])
          }
        }

    }
  }

  return data
}

export function matchQuotesAndPackages(data, packages){
  if(data && packages){
    for(let i=0; i<data.length; i++){
      data[i].packages = []
      data[i].inbound = []
      data[i].outbound = []
      data[i].inboundReceived = 0
      data[i].inboundConfirmed = 0
      data[i].outboundReceived = 0
      data[i].outboundConfirmed = 0
    }
  
    for(let i=0; i<packages.length; i++){

      let quoteNum = packages[i].nQuote
      if(quoteNum){
        let quote = data.find(x => x.id===quoteNum)
        if(quote){
          if(packages[i].nType === 1){
            quote.inbound.push(packages[i])
            if(packages[i].sStatusCode === "DE"){quote.inboundReceived++}
            if(packages[i].bCompleted === 1){quote.inboundConfirmed++}
          } else {
            quote.outbound.push(packages[i])
            if(packages[i].sStatusCode === "DE"){quote.outboundReceived++}
            if(packages[i].bCompleted === 1){quote.outboundConfirmed++}
          }
          quote.packages.push(packages[i])
        }
      }
    }
  }

  return data
}


export function matchQuotesAndData(data, quoteLines, POData, POLineData){
  console.log(data, quoteLines, POData, POLineData)
  for(let i=0; i<POData.length; i++){
    POData[i].TotalCost = POData[i].bTaxDel ? POData[i].nDelivery * (1 + data[i].nTaxRate/100) : 
    POData[i].Lines = []
  }
  for(let i=0; i<data.length; i++){
    data[i].TotalCost = 0
    data[i].TotalPrice = data[i].bTaxDel ? data[i].nDelivery * (1 + data[i].nTaxRate/100) : data[i].nDelivery
    data[i].POs = []
    data[i].Lines = []
  }
  for(let i=0; i<quoteLines.length; i++){
    let quote = data.find(x => x.id===quoteLines[i].nQuote)
    quote.Lines.push(quoteLines[i])
    quote.TotalPrice += quoteLines[i].nQuantity * quoteLines[i].nPrice
  }
  for(let i=0; i<POLineData.length; i++){
    let PO = POData.find(x => x.id===POLineData[i].nQuote)
    PO.Lines.push(POLineData[i])
    PO.TotalCost += POLineData[i].nQuantity * POLineData[i].nPrice
  }
  for(let i=0; i<POData.length; i++){
    let quote = data.find(x => x.id===POData[i].nAttachedQuote)
    quote.POs.push(POData[i])
    quote.TotalCost += POData[i].TotalCost
  }
  return data
}

export function matchQuotesAndPOLines(data, POData){


  for(let i=0; i<data.length; i++){
    data[i].POTotal = 0
    data[i].Labor = 0
  }
  for(let i=0; i<POData.length; i++){
    POData[i].Labor = (POData[i].nItem==832 ? POData[i].nQuantity * POData[i].nPrice : 0)

    let quoteNum = POData[i].nAttachedQuote
    if(quoteNum){
      let quote = data.find(x => x.id===quoteNum)
      if(quote && quote.POLines){
        quote.POLines.push(POData[i])
        quote.POTotal += POData[i].nQuantity * POData[i].nPrice* (1+POData[i].nTaxRate/100)
        if(quote.nDeliveryCost){
        quote.POTotal += POData[i].nDelivery * (POData[i].nTaxDel==1 ? (1+POData[i].nTaxRate/100) : 1)
        }
        quote.Labor += POData[i].Labor
      } else {
        quote.POLines = [POData[i]]
        quote.POTotal = POData[i].nQuantity * POData[i].nPrice * (1+POData[i].nTaxRate/100)
        quote.POTotal += POData[i].nDelivery * (POData[i].nTaxDel==1 ? (1+POData[i].nTaxRate/100) : 1)
        quote.Labor = POData[i].Labor
      }
    }    
    
  }

  return putCSNPOAtTop(data)
}
export function matchQuotesAndLines(data, lines){
  for(let i=0; i<data.length; i++){
    data[i].LinesTotal = 0
  }
  for(let i=0; i<lines.length; i++){

    let quoteNum = lines[i].nQuote
    if(quoteNum){
      let quote = data.find(x => x.id===quoteNum)
      if(quote && quote.Lines){
        quote.Lines.push(lines[i])
        quote.LinesTotal += lines[i].nQuantity * lines[i].nPrice
      } else {
        quote.Lines = [lines[i]]
        quote.LinesTotal = lines[i].nQuantity * lines[i].nPrice
      }
    }   
  }
  for(let i=0; i<data.length; i++){
    data[i].TotalPrice = data[i].LinesTotal + data[i].nDelivery
  }

  return data
}

export function matchFollowupsAndQuotes(followups, quotes){
  for(let i=0; i<quotes.length; i++){
    let followup = followups.find(x => x.nQuote===quotes[i].id)
    if(followup){
        followup.quote = quotes[i]
    }
}
return followups
}

export function putCSNPOAtTop(data){
  for(let j=0; j<data.length; j++){
    if(data[j].POLines){
      for(let i=0; i<data[j].POLines.length; i++){
        if(data[j].POLines[i].sCompany && data[j].POLines[i].sCompany.slice(0,3) === "CSN"){
          data[j].POLines.unshift(data[j].POLines.splice(i, 1)[0])
        }
      } 
    }
  }
  
  return data
}

export function calcOpenInstallChartData(data, numWeeks){
    let weeks = []
    let marginPlusLabor = []
    let target = []
    let laborTotal = 0
    let marginPlusLaborTotal = 0
    let marginTotal = 0
    let totalSales = 0
    let totalMargin = 0
    let totalLabor = 0
    let totalMarginPlusLabor = 0
    let topMarginPlusLabor = 0
    let zerosInMarginPlusLabor = 0
    let upperBound = 0

    for(let i=0; i<numWeeks; i++){
      weeks[i] = weekStart(6-i*7).slice(0,5)
      marginPlusLabor[i] = 0
      target[i] = 7500
    }

    for(let i=0; i<data.length; i++){

      laborTotal += data[i].nLaborTotals
      marginPlusLaborTotal += data[i].nQuoteTotal - data[i].nPoTotals + data[i].nLaborTotals
      marginTotal += data[i].nQuoteTotal - data[i].nPoTotals

      let week = Math.floor(Math.floor((moment(data[i].dtTargetComplete) - moment(weekStart(6)))/1000/24/3600)/7)
      if(week<numWeeks){
        data[i].nQuoteTotal = data[i].nQuoteTotal / (1 + data[i].nTaxRate/100)
        marginPlusLabor[week] += data[i].nQuoteTotal - data[i].nPoTotals + data[i].nLaborTotals
        totalSales += data[i].nQuoteTotal
        totalMargin += data[i].nQuoteTotal - data[i].nPoTotals
        totalLabor += data[i].nLaborTotals
        totalMarginPlusLabor += data[i].nQuoteTotal - data[i].nPoTotals + data[i].nLaborTotals
      }

    }

    topMarginPlusLabor = Math.max(...marginPlusLabor)
    zerosInMarginPlusLabor = Math.floor(Math.log10(topMarginPlusLabor))

    upperBound = Math.ceil(topMarginPlusLabor / Math.pow(10, zerosInMarginPlusLabor)) * Math.pow(10, zerosInMarginPlusLabor)

    weeks = weeks.slice(0, -3)
    marginPlusLabor = marginPlusLabor.slice(0, -3)

    return {
        laborTotal: laborTotal,
        marginPlusLaborTotal: marginPlusLaborTotal,
        marginTotal: marginTotal,
        loaded: true,
        weeks: weeks, 
        marginPlusLabor: marginPlusLabor, 
        upperBound: upperBound,
        totalLabor: totalLabor,
        totalMargin: totalMargin,
        totalSales: totalSales,
        totalMarginPlusLabor: totalMarginPlusLabor,
        target: target,
      }
  }

  export function showQuotesNoSecondRep(quotes){
    let quotesNoSecondRep = []
    if(quotes.length > 0){
      for(let i=0; i<quotes.length; i++){
        if(!quotes[i].nRep2 > 0){
          quotesNoSecondRep.push(quotes[i])
        }
      }
    }

    return quotesNoSecondRep
  }

  export function showQuotesLocal(quotes){
    let quotesLocal = []
    if(quotes.length > 0){
      for(let i=0; i<quotes.length; i++){
        if(activeInstallers().length > 0){
          for(let j=0; j<activeInstallers().length; j++){
            if(activeInstallers()[j] == quotes[i].nRep2){
              quotesLocal.push(quotes[i])
            }
          }
        }
      }
    }

    return quotesLocal
  }

  export function showQuotesContract(quotes){
    let quotesContract = []
    if(quotes.length > 0){
      for(let i=0; i<quotes.length; i++){
        if(activeCoordinators().length > 0){
          for(let j=0; j<activeCoordinators().length; j++){
            if(activeCoordinators()[j] == quotes[i].nRep2){
              quotesContract.push(quotes[i])
            }
          }
        }
      }
    }

    return quotesContract
  }
  