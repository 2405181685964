import React, { Component } from 'react';
import { getSQLData } from '../../assets/functions/fetch';
import { matchQuotesAndData, matchQuotesAndLines, matchQuotesAndPOCosts } from '../../assets/functions/process/cam';
import { CSVLink } from 'react-csv';
import { matchQuotesAndPOs } from '../../assets/functions/process/quotes';

class AccountingDashboardv2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '1/17/25',
            endDate: '2/3/25',
        };

      }

      componentDidMount() {
          this.fetchData();
        }
      
        fetchData = async () => {
            let startDate = this.state.startDate
            let endDate = this.state.endDate
      
          try {
            const fetch = await getSQLData("commission_log/getLastPeriodLog", {start: startDate, end: endDate,})
            const fetchLines = await getSQLData("commission_log/getLastPeriodLines", {start: startDate, end: endDate,})
            const fetchPOs = await getSQLData("commission_log/getLastPeriodPOs", {start: startDate, end: endDate,})
            const fetchPOLines = await getSQLData("commission_log/getLastPeriodPOLines", {start: startDate, end: endDate,})
            
            if(fetch && fetchPOs && fetchLines && fetchPOLines){
      
              let data = fetch.data.data[0].recordset          
              let POData = fetchPOs.data.data[0].recordset      
              let quoteLines = fetchLines.data.data[0].recordset
              let POLines = fetchPOLines.data.data[0].recordset
              
              
              this.setState({
                data: data,
                POData: POData,
                quoteLines: quoteLines,
                POLines: POLines,
              }, () => this.processData())
            }
               
          } catch (error) {
            console.error(error);
          }
        }
      
        processData() {
            let data = this.state.data
            let POData = this.state.POData
            let quoteLines = this.state.quoteLines
            let POLines = this.state.POLines
        
            data = matchQuotesAndData(data, quoteLines, POData, POLines)

            let CSVData = []

            for(let i=0; i<data.length; i++){
                CSVData.push({
                    id: data[i].id,
                    nRep: data[i].nRep,
                    TotalPrice: data[i].TotalPrice,
                    TotalCost: data[i].TotalCost,
                })
            }

            console.log(data, CSVData)
            
            this.setState({
                data: data,
                CSVData: CSVData,

                loaded: true,
            })
        }
    render() {
        let data = this.state.data
        let CSVData = this.state.CSVData
        let loaded = this.state.loaded

        return (
            <div>
                {loaded && (

                <>
                <CSVLink data={CSVData} filename={"check_commission_log"}>Download</CSVLink>
                <table>
                    <thead>
                        <tr>
                            <th>Rep</th>
                            <th>Quote</th>
                            <th>Total</th>
                            <th>PO</th>
                        </tr>
                    </thead>
                    {data.map((quote,index)=>(
                        <tr>
                            <td>{quote.nRep}</td>
                            <td>{quote.id}</td>
                            <td>{quote.TotalPrice}</td>
                            <td>{quote.TotalCost}</td>
                        </tr>
                    ))}
                </table>
                </>
                )}
            </div>
        );
    }
}

export default AccountingDashboardv2;