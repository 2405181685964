import moment from "moment"
import { weekStart } from "../time"
import { calcUpperBound } from "../calculations"

export function matchNewQuotesToWeeks(data){
    let weeks = []
    let numWeeks = 110
    let metrics = []
    let weekQuotes = []
    let reps = []

    const metricTemplate = {
        count: 0,
        quotes: 0,
        quoted: 0,
        quoteTotal: 0,
        open: 0,
        closed: 0,
        discontinued: 0,
        spam: 0,
        noRFQ: 0,
        movingAverage: 0,
        movingAverageQuoted: 0,
        movingAverageQuotedTotal: 0,
        targetCount: 0,
        targetQuoted: 0,
        targetQuotedTotal: 0,
        upperBoundCount: 0,
        upperBoundQuoted: 0,
        upperBoundQuotedTotal: 0,
        potential: 0,
        targetPotential: 0,
        movingAveragePotential: 0,
        upperBoundPotential: 0,
    }

    for(let i=0; i<numWeeks; i++){
        weeks[i] = weekStart(6+i*7).slice(0,5)
        weekQuotes[i] = []
        metrics[i] = {
            Web: {...metricTemplate},
            Email: {...metricTemplate},
            Phone: {...metricTemplate},
            Chat: {...metricTemplate},
            Repeat: {...metricTemplate},
            Other: {...metricTemplate},
            NewCustomer: {...metricTemplate},
            Total: {...metricTemplate},
            HighPotential: {...metricTemplate},
            RFQsSent: {...metricTemplate},
            RFQsFullMatch: {...metricTemplate},
            Spam: {...metricTemplate},
        }
        reps[i] = []
    }

    for(let i=0; i<data.length; i++){
        let week = Math.floor(Math.floor((moment(weekStart(0)) - moment(data[i].dtDateStamp))/1000/24/3600)/7)
        data[i].NewCustomer = !data[i].FirstSale || (data[i].dtFirstClosed === data[i].FirstSale)

        weekQuotes[week].push(data[i])

        if(!reps[week][data[i].nRep]){
            reps[week][data[i].nRep] = {
                Web: {...metricTemplate},
                Email: {...metricTemplate},
                Phone: {...metricTemplate},
                Chat: {...metricTemplate},
                Repeat: {...metricTemplate},
                Other: {...metricTemplate},
                NewCustomer: {...metricTemplate},
                Total: {...metricTemplate},
                HighPotential: {...metricTemplate},
                nRep: data[i].nRep,
                RFQsSent: {...metricTemplate},
                RFQsFullMatch: {...metricTemplate},
                Spam: {...metricTemplate},
            }
        }

        metrics[week].Total = updateMetric(data[i], metrics[week].Total)
        reps[week][data[i].nRep].Total = updateMetric(data[i], reps[week][data[i].nRep].Total)

        if(data[i].bDeleted > 0){
            metrics[week].Spam = updateMetric(data[i], metrics[week].Spam)
            reps[week][data[i].nRep].Spam = updateMetric(data[i], reps[week][data[i].nRep].Spam)
        } else {

        

        if(data[i].NewCustomer && !(data[i].bDeleted>0)){
            metrics[week].NewCustomer = updateMetric(data[i], metrics[week].NewCustomer)
            reps[week][data[i].nRep].NewCustomer = updateMetric(data[i], reps[week][data[i].nRep].NewCustomer)
        }

        switch(data[i].sType){
            case "Web":
                metrics[week].Web = updateMetric(data[i], metrics[week].Web)
                reps[week][data[i].nRep].Web = updateMetric(data[i], reps[week][data[i].nRep].Web)
                break;
            case "Email":
                metrics[week].Email = updateMetric(data[i], metrics[week].Email)
                reps[week][data[i].nRep].Email = updateMetric(data[i], reps[week][data[i].nRep].Email)
                break;
            case "Phone":
                metrics[week].Phone = updateMetric(data[i], metrics[week].Phone)
                reps[week][data[i].nRep].Phone = updateMetric(data[i], reps[week][data[i].nRep].Phone)
                break;
            case "Repeat":
                metrics[week].Repeat = updateMetric(data[i], metrics[week].Repeat)
                reps[week][data[i].nRep].Repeat = updateMetric(data[i], reps[week][data[i].nRep].Repeat)
                break;
            case "HelpOnClick":
                metrics[week].Chat = updateMetric(data[i], metrics[week].Chat)
                reps[week][data[i].nRep].Chat = updateMetric(data[i], reps[week][data[i].nRep].Chat)
                break;
            default:
                metrics[week].Other = updateMetric(data[i], metrics[week].Other)
                reps[week][data[i].nRep].Other = updateMetric(data[i], reps[week][data[i].nRep].Other)
        }
        
        if(data[i].nPotential>=4){
            metrics[week].HighPotential = updateMetric(data[i],metrics[week].HighPotential)
            reps[week][data[i].nRep].HighPotential = updateMetric(data[i], reps[week][data[i].nRep].HighPotential)
        }

        if(data[i].RFQsSent > 0){
            metrics[week].RFQsSent.quotes += 1
            metrics[week].RFQsSent.count += data[i].RFQsSent
            reps[week][data[i].nRep].RFQsSent.quotes += 1
            reps[week][data[i].nRep].RFQsSent.count += data[i].RFQsSent
        }
        if(data[i].RFQsFullMatch > 0){
            metrics[week].RFQsFullMatch.quotes += 1
            metrics[week].RFQsFullMatch.count += data[i].RFQsFullMatch
            reps[week][data[i].nRep].RFQsFullMatch.quotes += 1
            reps[week][data[i].nRep].RFQsFullMatch.count += data[i].RFQsFullMatch
        }

        }

    }

    for(let i=0; i<numWeeks-4; i++){
        for(const category in metrics[i]){
            metrics[i][category].movingAverage = 
                (metrics[i][category].count + metrics[i+1][category].count + metrics[i+2][category].count + metrics[i+3][category].count
                    -(metrics[i][category].spam + metrics[i+1][category].spam + metrics[i+2][category].spam + metrics[i+3][category].spam)
                )/4
            metrics[i][category].movingAverageQuoted = (metrics[i][category].quoted + metrics[i+1][category].quoted + metrics[i+2][category].quoted + metrics[i+3][category].quoted)/4
            metrics[i][category].movingAverageQuotedTotal = (metrics[i][category].quotedTotal + metrics[i+1][category].quotedTotal + metrics[i+2][category].quotedTotal + metrics[i+3][category].quotedTotal)/4
            metrics[i].Total.movingAveragePotential = (metrics[i].Total.potential + metrics[i+1].Total.potential + metrics[i+2].Total.potential + metrics[i+3].Total.potential)/4
        }
        metrics[i].NewCustomer.targetCount = 80
        metrics[i].Repeat.targetCount = 40
        metrics[i].Total.targetCount = 120
        metrics[i].Total.targetPotential = 225000

        weekQuotes[i].sort((a,b)=>b.nPotential*1000+b.nLikelyToClose+b.nLinesTotal/1000000-a.nPotential*1000-a.nLikelyToClose-a.nLinesTotal/1000000)
        reps[i].sort((a,b) => b.NewCustomer.count - a.NewCustomer.count)
    }

    let NewCustomer = {
        data: [],
        movingAverage: [],
        targetCount: [],
        upperBound: 0,
    }
    for(let i=0; i<numWeeks; i++){
        NewCustomer.data[i] = metrics[i].NewCustomer.count - metrics[i].NewCustomer.spam
        NewCustomer.movingAverage[i] = metrics[i].NewCustomer.movingAverage ? metrics[i].NewCustomer.movingAverage : 0
        NewCustomer.targetCount[i] = metrics[i].NewCustomer.targetCount ? metrics[i].NewCustomer.targetCount : 0
    }
    NewCustomer.upperBound = calcUpperBound(NewCustomer.data)

    let Repeat = {
        data: [],
        movingAverage: [],
        targetCount: [],
        upperBound: 0,
    }
    for(let i=0; i<numWeeks; i++){
        Repeat.data[i] = metrics[i].Repeat.count
        Repeat.movingAverage[i] = metrics[i].Repeat.movingAverage ? metrics[i].Repeat.movingAverage : 0
        Repeat.targetCount[i] = metrics[i].Repeat.targetCount ? metrics[i].Repeat.targetCount : 0
    }
    Repeat.upperBound = calcUpperBound(Repeat.data)

    let Total = {
        data: [],
        movingAverage: [],
        targetCount: [],
        upperBound: 0,
    }
    for(let i=0; i<numWeeks; i++){
        Total.data[i] = metrics[i].Total.count - metrics[i].Total.spam
        Total.movingAverage[i] = metrics[i].Total.movingAverage ? metrics[i].Total.movingAverage : 0
        Total.targetCount[i] = metrics[i].Total.targetCount ? metrics[i].Total.targetCount : 0
    }
    Total.upperBound = calcUpperBound(Total.data)

    let Potential = {
        data: [],
        movingAverage: [],
        targetCount: [],
        upperBound: 0,
    }
    for(let i=0; i<numWeeks; i++){
        Potential.data[i] = metrics[i].Total.potential
        Potential.movingAverage[i] = metrics[i].Total.movingAveragePotential ? metrics[i].Total.movingAveragePotential : 0
        Potential.targetCount[i] = metrics[i].Total.targetPotential ? metrics[i].Total.targetPotential : 0
    }
    Potential.upperBound = calcUpperBound(Potential.data)


  
    let summaryData = {
      weeks: weeks,
      NewCustomer: NewCustomer,
      Repeat: Repeat,
      Total: Total,
      Potential: Potential,
      weekQuotes: weekQuotes,
      reps: reps,
      metrics: metrics,
    }

    return summaryData
  }

export function updateMetric(quote, metric){
    metric.count++

    if(quote.sStatus === "Open"){
        metric.open++
    }
    if(quote.sStatus === "Closed"){
        metric.closed++
    }
    if(quote.sStatus === "Discontinued"){
        metric.discontinued++
    }

    if(quote.nLinesTotal > 0){
        metric.quoted++
        metric.quoteTotal += quote.nLinesTotal + quote.nDelivery
        if(!(quote.POTotal > 0)){
            metric.noRFQ++
        }
    }

    if(quote.bDeleted > 0){
        metric.spam++
    }

    

    metric.potential += (quote.nPotential === 1 ? 100 :
        quote.nPotential === 2 ? 500 :
        quote.nPotential === 3 ? 2500 :
        quote.nPotential === 4 ? 7500 : 
        quote.nPotential === 5 ? 15000 : 0)
    
    return metric
}

export function matchClosedToWeeks(data){
    let weeks = []
    let numWeeks = 110
    let margin = []
    let sales = []
    let targetSales = []
    let targetMargin = []
    let numQuotes = []
    let movingAverageSales = []
    let movingAverageMargin = []
    let weekQuotes = []
    let newCustomers = []
    let newCustomerSales = []
    let targetNewCustomers = []
    let targetNewCustomerSales = []
    let movingAverageNewCustomers = []
    let movingAverageNewCustomerSales = []

    let reps = []
    const repTemplate = {
        numQuotes: 0,
        totalSales: 0,
        totalMargin: 0,
        averageMargin: 0,
        newCustomers: 0,
        newCustomerSales: 0,
    }
  
    for(let i=0; i<numWeeks; i++){
      weeks[i] = weekStart(6+i*7).slice(0,5)
      margin[i] = 0
      sales[i] = 0      
      targetSales[i] = 80000
      targetMargin[i] = 30000
      movingAverageSales[i] = 0
      movingAverageMargin[i] = 0
      weekQuotes[i] = []
      reps[i] = []

      newCustomers[i] = 0
      newCustomerSales[i] = 0
      targetNewCustomers[i] = 10
      targetNewCustomerSales[i] = 20000

    }
  
    for(let i=0; i<data.length; i++){
      let week = Math.floor(Math.floor((moment(weekStart(0)) - moment(data[i].dtFirstClosed))/1000/24/3600)/7)

      if(week<numWeeks){
        numQuotes[week] += 1
        data[i].TotalPrice = data[i].nLinesTotal + data[i].nDelivery
        data[i].margin = data[i].TotalPrice - data[i].POTotal
        
        margin[week] += data[i].margin
        sales[week] += data[i].TotalPrice

        data[i].NewCustomer = (data[i].dtFirstClosed === data[i].FirstSale)
        if(data[i].NewCustomer){
            newCustomers[week] += 1
            newCustomerSales[week] += data[i].TotalPrice
        }

        weekQuotes[week].push(data[i])

        if(!reps[week][data[i].nRep]){
            reps[week][data[i].nRep] = {...repTemplate}
            reps[week][data[i].nRep].nRep=data[i].nRep
            
        }
        reps[week][data[i].nRep].numQuotes += 1
        reps[week][data[i].nRep].totalSales += data[i].TotalPrice
        reps[week][data[i].nRep].totalMargin += data[i].margin
        if(data[i].NewCustomer){
            reps[week][data[i].nRep].newCustomers += 1
            reps[week][data[i].nRep].newCustomerSales += data[i].TotalPrice
        }

        
      }
    }
  
    for(let i=0; i<numWeeks-4; i++){
      movingAverageSales[i] = (sales[i] + sales[i+1] + sales[i+2] + sales[i+3])/4
      movingAverageMargin[i] = (margin[i] + margin[i+1] + margin[i+2] + margin[i+3])/4
      movingAverageNewCustomers[i] = (newCustomers[i] + newCustomers[i+1] + newCustomers[i+2] + newCustomers[i+3])/4
      movingAverageNewCustomerSales[i] = (newCustomerSales[i] + newCustomerSales[i+1] + newCustomerSales[i+2] + newCustomerSales[i+3])/4
    }
  
    let upperBoundMargin = calcUpperBound(margin)
    let upperBoundSales = calcUpperBound(sales)
    let upperBoundNewCustomers = calcUpperBound(newCustomers)
    let upperBoundNewCustomerSales = calcUpperBound(newCustomerSales)
  
    for(let i=0; i< weekQuotes.length; i++){
        weekQuotes[i].sort((a,b)=>b.margin-a.margin)
    }    

    for(let i=0; i<reps.length; i++){
        for(let j=0; j<reps[i].length; j++){
            if(reps[i][j] && reps[i][j].totalSales > 0){
                reps[i][j].averageMargin = reps[i][j].totalMargin / reps[i][j].totalSales
            }
        }
        reps[i].sort((a,b)=>b.totalMargin-a.totalMargin)
    }

    let summaryData = {
      weeks: weeks,
      sales: sales,
      margin: margin,
      movingAverageSales: movingAverageSales,
      movingAverageMargin: movingAverageMargin,
      targetSales: targetSales,
      targetMargin: targetMargin,
      upperBoundMargin: upperBoundMargin,
      upperBoundSales: upperBoundSales,
      weekQuotes: weekQuotes,
      reps: reps,
      newCustomers: newCustomers,
      newCustomerSales: newCustomerSales,
      targetNewCustomers: targetNewCustomers,
      targetNewCustomerSales: targetNewCustomerSales,
      upperBoundNewCustomers: upperBoundNewCustomers,
      upperBoundNewCustomerSales: upperBoundNewCustomerSales,
      movingAverageNewCustomers: movingAverageNewCustomers,
      movingAverageNewCustomerSales: movingAverageNewCustomerSales,
    }
  
    return summaryData
  }

  export function organizeChartDataRac(months){
    let chartData = {}
    chartData.labels = []
    chartData.data = []
    let years = months[0].years.length
    for(let i=0; i<years; i++){
      chartData.data.push([])
    }
    chartData.target = []
  
    console.log(months)
  
    for(let i=0; i<months.length; i++){
      chartData.labels.push(moment(months[i].years[0].month).format("MM/YY"))
      for(let j=0; j<years; j++){
        chartData.data[j].push(months[i].years[j].completed.margin.all+months[i].years[j].completed.labor)
      }
      chartData.target.push(months[i].targetMargin[0])
    }
  
    chartData.lowerBound = 0
    chartData.upperBound = Math.max(...chartData.target)
    for(let i=0; i<years; i++){
      chartData.upperBound = Math.max(...chartData.data[i], chartData.upperBound)
    }
  
    let zeroes = Math.floor(Math.log10(chartData.upperBound))-1
    chartData.upperBound = Math.ceil(chartData.upperBound/Math.pow(10,zeroes))*Math.pow(10,zeroes)
  
    chartData.labels.reverse()
    chartData.data[0].reverse()
    chartData.data[1].reverse()
    chartData.target.reverse()
  
    return chartData
  }