import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { matchQuotesAndEmails, matchQuotesAndLines, matchQuotesAndNotes, matchQuotesAndPOCosts } from '../../assets/functions/process/cam';
import { matchQuotesAndNotesv2, matchQuotesAndPOs, matchQuotesAndRFQs } from '../../assets/functions/process/quotes';
import moment from 'moment';
import { CustomDateWrapper, Input } from '../styled/ui';

class FollowupEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rep: this.props.user,
            updateList: [],
            selectedFollowup: -1,
        };

      }

      componentDidMount() {
          this.fetchData();
        }
      
        fetchData = async () => {
            let rep = this.state.rep
      
          try {
            const fetchFollowups = await getSQLData("sales_operations/rep_followups", {nRep: rep})
            const fetchQuotes = await getSQLData("sales_operations/rep_followup_quotes", {nRep: rep})
            const fetchQuoteLines = await getSQLData("sales_operations/rep_followup_quote_lines", {nRep: rep})
            const fetchPOs = await getSQLData("sales_operations/rep_followup_POs", {nRep: rep})
            const fetchRFQs = await getSQLData("sales_operations/rep_followup_RFQs", {nRep: rep})
            const fetchNotes = await getSQLData("sales_operations/rep_followup_notes", {nRep: rep})
            const fetchEmails = await getSQLData("sales_operations/rep_followup_emails", {nRep: rep})
            
            if(fetchFollowups && fetchQuotes && fetchQuoteLines && fetchPOs && fetchRFQs && fetchNotes && fetchEmails){
      
              let followups = fetchFollowups.data.data[0].recordset                        
              let quotes = fetchQuotes.data.data[0].recordset                        
              let quoteLines = fetchQuoteLines.data.data[0].recordset                        
              let POs = fetchPOs.data.data[0].recordset                        
              let RFQs = fetchRFQs.data.data[0].recordset                        
              let notes = fetchNotes.data.data[0].recordset                        
              let emails = fetchEmails.data.data[0].recordset                        
              
              this.setState({
                followups: followups,
                quotes: quotes,
                quoteLines: quoteLines,
                POs: POs,
                RFQs: RFQs,
                notes: notes,
                emails: emails,
              }, () => this.processData())
            }
               
          } catch (error) {
            console.error(error);
          }
        }
      
        processData() {
            let followups = this.state.followups
            let quotes = this.state.quotes
            let quoteLines = this.state.quoteLines
            let POs = this.state.POs
            let RFQs = this.state.RFQs
            let notes = this.state.notes
            let emails = this.state.emails
            let metrics = {
                late: 0,
                today: 0,
                tomorrow: 0,
                busDays2: 0,
                busDays3: 0,
                busDays4: 0,
                busDays5: 0,
                thisMonth: 0,
                thisQuarter: 0,
                thisYear: 0,
                nextYear: 0,
            }
            
            quotes = matchQuotesAndLines(quotes, quoteLines)
            quotes = matchQuotesAndPOs({quotes: quotes, POs: POs}).quotes
            quotes = matchQuotesAndRFQs({quotes: quotes, RFQs: RFQs}).quotes
            quotes = matchQuotesAndNotesv2({quotes: quotes, notes: notes}).quotes
            quotes = matchQuotesAndEmails({quotes, emails}).quotes

            for(let i=0; i<quotes.length; i++){
                let followup = followups.find(x => x.nQuote===quotes[i].id)
                if(followup){
                    followup.quote = quotes[i]
                }
            }

            let now = moment().utc();
            let endOfToday = moment().utc().endOf("day");
            let tomorrow = moment().utc().add(1, "days").endOf("day");
            let busDays2 = moment().utc().add(2, "days").endOf("day");
            let busDays3 = moment().utc().add(3, "days").endOf("day");
            let busDays4 = moment().utc().add(4, "days").endOf("day");
            let busDays5 = moment().utc().add(5, "days").endOf("day");
            let thisMonth = moment().utc().add(1, "month").endOf("day");
            let thisQuarter = moment().utc().add(3, "month").endOf("day");
            let thisYear = moment().utc().add(12, "month").endOf("day");

            for(let i=0; i<followups.length; i++){
                followups[i].dtDate = moment(followups[i].dtDate).utc().format("YYYY-MM-DDTHH:mm")
                let date = moment(followups[i].dtDate).utc()

                if (date.isBefore(now)) {
                    metrics.late++
                } else if (date.isSameOrBefore(endOfToday)) {
                    metrics.today++
                } else if (date.isSameOrBefore(tomorrow)){
                    metrics.tomorrow++
                } else if (date.isSameOrBefore(busDays2)){
                    metrics.busDays2++
                } else if (date.isSameOrBefore(busDays3)){
                    metrics.busDays3++
                } else if (date.isSameOrBefore(busDays4)){
                    metrics.busDays4++
                } else if (date.isSameOrBefore(busDays5)){
                    metrics.busDays5++
                } else if (date.isSameOrBefore(thisMonth)){
                    metrics.thisMonth++
                } else if (date.isSameOrBefore(thisQuarter)){
                    metrics.thisQuarter++
                } else if (date.isSameOrBefore(thisYear)){
                    metrics.thisYear++
                } else {
                    metrics.nextYear++
                }
            }

            let CSVData = []

            for(let i=0; i<followups.length; i++){
                CSVData.push({

                })
            }

            console.log(followups)
            
            this.setState({
                followups: followups,
                CSVData: CSVData,
                metrics: metrics,
                loaded: true,
            })
        }

        updateFollowup(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sNote = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sNote = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        updateCompleteNote(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sComplete = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sComplete = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        completeFollowup = async(index) => {
            let followups = this.state.followups
            let followup = followups[index]
            let leaveNote = false
            let nextFollowup = false

            if(followup.sNote) {
                leaveNote = true
            }

            if(followup.sNextFollowup){
                nextFollowup = true
            }

            try {
                const completeFollowup = await setSQLData("sales_operations/completeFollowup",{
                    id: followup.id,
                    nCompleted: this.props.user,
                })

                if(leaveNote){
                    const leaveNote = await setSQLData("sales_operations/leaveNote",{
                        nRep: this.props.user,
                        nQuote: followup.nQuote,
                        sNote: followup.sComplete,
                    })
                }

                if(nextFollowup){
                    const scheduleFollowup = await setSQLData("sales_operations/scheduleFollowup",{
                        nQuote: followups[index].nQuote,
                        nAdded: this.props.user,
                        nRep: this.state.rep,
                        sNote: followups[index].sNextFollowup,
                        dtDate: moment(followups[index].dtNextDate).format("MM/DD/YYYY hh:mm A"),
                    })
                }

                
            } catch {

            }
        }

        saveFollowupChange = async (index) => {
            let followups = this.state.followups
            
            try {
                const updateFollowup = await setSQLData("sales_operations/updateFollowup",{
                    id: followups[index].id,
                    sNote: followups[index].sNote,
                    dtDate: moment(followups[index].dtDate).format("MM/DD/YYYY hh:mm A"),
                })

            } catch {

            }
        }

        updateData(index, value, type){
            let followups = this.state.followups
            let updateList = this.state.updateList

            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                switch(type){
                    case "date":
                        followups[index].dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateExists.dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        break;
                    case "followup":
                        followups[index].sNote = value
                        updateExists.sNote = value
                        break;
                    case "note":
                        followups[index].sComplete = value
                        updateExists.sComplete = value
                        break;
                    case "next date":
                        followups[index].dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateExists.dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        break;
                    case "next followup":
                        followups[index].sNextFollowup = value
                        updateExists.sNextFollowup = value
                        break;
                }
                
            } else {
                switch(type){
                    case "date":
                        followups[index].dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateList.push(followups[index])
                        break;
                    case "followup":
                        followups[index].sNote = value
                        updateList.push(followups[index])
                        break;
                    case "note":
                        followups[index].sComplete = value
                        updateList.push(followups[index])
                        break;
                    case "next date":
                        followups[index].dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateList.push(followups[index])
                        break;
                    case "next followup":
                        followups[index].sNextFollowup = value
                        updateList.push(followups[index])
                        break;
                }
                
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        selectFollowup(index){
            this.setState({
                selectedFollowup: index,
            })
        }

        updateRep(rep){
            this.setState({rep: parseInt(rep)}, ()=>this.fetchData())
        }
    render() {
        let followups = this.state.followups
        let updateList = this.state.updateList
        let loaded = this.state.loaded
        let selectedFollowupIndex = this.state.selectedFollowup
        let selectedFollowup = (loaded && selectedFollowupIndex >= 0) ? followups[selectedFollowupIndex] : {}
        let metrics = this.state.metrics

        return (
            <div>
                {loaded && (

                <>
                    <div style={{ display: 'inline-block', width: '50%', border: '1px solid black', height: '800px', overflowY: 'auto', verticalAlign: 'top' }}>
                        {followups.map((followup, index) => (
                            <div key={index} style={{ padding: '5px', border: '1px solid black' }} onClick={()=>this.selectFollowup(index)}>
                                <div style={{ display: 'inline-block', width: '10%' }}>{followup.nQuote}</div>
                                <div style={{ display: 'inline-block', width: '30%' }}>
                                    {followup.quote?.sName} <br /> {followup.quote?.sPhone} {followup.quote?.sEmail}
                                </div>
                                <div style={{ display: 'inline-block', width: '20%' }}>{followup.quote?.sCompany}</div>
                                <div style={{ display: 'inline-block', width: '40%' }}>
                                    {moment(followup.dtDate).format("MM/DD/YYYY hh:mm A")} <br /> {followup.sNote}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'inline-block', width: '50%', border: '1px solid black', verticalAlign: 'top' }}>
                        {selectedFollowupIndex === -1 && (
                            <div>
                                <table>
                                    <tr>
                                        <td>Followups</td>
                                        <td>{followups.length}</td>
                                    </tr>
                                    <tr>
                                        <td>Late</td>
                                        <td>{metrics.late}</td>
                                    </tr>
                                    <tr>
                                        <td>Today</td>
                                        <td>{metrics.today}</td>
                                    </tr>
                                    <tr>
                                        <td>Tomorrow</td>
                                        <td>{metrics.tomorrow}</td>
                                    </tr>
                                    <tr>
                                        <td>The Day After Tomorrow</td>
                                        <td>{metrics.busDays2}</td>
                                    </tr>
                                    <tr>
                                        <td>The Day After The Day After Tomorrow</td>
                                        <td>{metrics.busDays3}</td>
                                    </tr>
                                    <tr>
                                        <td>The Day After The Day After The Day After Tomorrow</td>
                                        <td>{metrics.busDays4}</td>
                                    </tr>
                                    <tr>
                                        <td>The Day After The Day After The Day After The Day After Tomorrow</td>
                                        <td>{metrics.busDays5}</td>
                                    </tr>
                                    <tr>
                                        <td>This Month</td>
                                        <td>{metrics.thisMonth}</td>
                                    </tr>
                                    <tr>
                                        <td>This Quarter</td>
                                        <td>{metrics.thisQuarter}</td>
                                    </tr>
                                    <tr>
                                        <td>This Year</td>
                                        <td>{metrics.thisYear}</td>
                                    </tr>
                                    <tr>
                                        <td>Next Year</td>
                                        <td>{metrics.nextYear}</td>
                                    </tr>
                                </table>
                            </div>
                        )}
                        {selectedFollowupIndex >= 0 && (
                            <div>
                                <div style={{position: 'relative'}}><button onClick={()=>this.setState({selectedFollowup: -1})}>X</button></div>
                                <table>
                                    <tr>
                                        <td>Followup</td>
                                        <td>
                                    <CustomDateWrapper>
                                        <Input
                                            onChange={(e) => {
                                            this.updateData(selectedFollowupIndex, e.target.value, "date");
                                            }}
                                            type="datetime-local"
                                            value={moment(selectedFollowup.dtDate).format("YYYY-MM-DDTHH:mm")}
                                            style={{width:'200px',}}
                                        />
                                    </CustomDateWrapper>
                                            
                                    <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.updateData(selectedFollowupIndex, e.target.value, "followup")} value={selectedFollowup.sNote} />

                                    {updateList.some(x => x.id === selectedFollowup.id) && <button onClick={()=>this.saveFollowupChange(selectedFollowupIndex)}>Update</button>}
                                    </td>
                                </tr>
                                    <tr>
                                        <td>Note to leave when completing</td>
                                        <td>
                                            <textarea style={{width: '200px', height:'125px',}} onChange={(e)=>this.updateData(selectedFollowupIndex, e.target.value, "note")} value={selectedFollowup.sComplete} />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Next Followup (leave blank if none)</td>
                                        <td>
                                        <CustomDateWrapper>
                                            <Input
                                                onChange={(e) => {
                                                this.updateData(selectedFollowupIndex, e.target.value, "next date");
                                                }}
                                                type="datetime-local"
                                                value={selectedFollowup.dtNextDate ? moment(selectedFollowup.dtNextDate).format("YYYY-MM-DDTHH:mm") : ""}
                                                style={{width:'200px',}}
                                            />
                                        </CustomDateWrapper>
                                <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.updateData(selectedFollowupIndex, e.target.value, "next followup")} value={selectedFollowup.sNextFollowup}/>
                                        </td>
                                        <tr>
                                        <td></td>
                                        <td><button onClick={(e)=>this.completeFollowup(selectedFollowupIndex)}>Complete</button></td>
                                        </tr>
                                    </tr>
                                </table>
                                <div>
                                    <div>
                                            <div style={{ display: 'inline-block', width: '10%' }}>
                                                {selectedFollowup.nQuote}
                                            </div>
                                            <div style={{ display: 'inline-block', width: '30%' }}>
                                                {selectedFollowup.quote?.sName} <br /> 
                                                {selectedFollowup.quote?.sPhone} {selectedFollowup.quote?.sEmail}
                                            </div>
                                            <div style={{ display: 'inline-block', width: '20%' }}>
                                                {selectedFollowup.quote?.sCompany} {selectedFollowup.quote?.ClosedQuotes} {selectedFollowup.quote?.OpenQuotes} {selectedFollowup.quote?.DiscontinuedQuotes}
                                            </div>
                                            <div style={{ display: 'inline-block', width: '40%' }}>
                                                {selectedFollowup.dtDate} <br /> {selectedFollowup.sNote}
                                            </div>
                                    </div>
                                    <div>
                                            <div style={{display: 'inline-block', width: '50%'}}>
                                                <div>{selectedFollowup.quote?.sAddress}</div>
                                                <div>{selectedFollowup.quote?.sAddress2}</div>
                                                <div>{selectedFollowup.quote?.sCity}, {selectedFollowup.quote?.sState} {selectedFollowup.quote?.sZip}</div>
                                                <div>{moment(selectedFollowup.quote?.dtDeliveryDate).format("MM/DD/YYYY")} - {moment(selectedFollowup.quote?.dtPickupDate).format("MM/DD/YYYY")}</div>
                                            </div>
                                            <div style={{display: 'inline-block', width: '50%'}}>
                                                <table>
                                                    {selectedFollowup.quote?.Lines?.map((line, lineIndex)=>(
                                                        <tr>
                                                            <td>{line.sDescription}</td>
                                                            <td>{line.nQuantity}</td>
                                                            <td>{line.nPrice}</td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                            
                                    </div>
                                    <div>
                                        {selectedFollowup.quote?.RFQs?.map((RFQ, RFQindex)=>(
                                            <div>{RFQ.nMember} {RFQ.dtSubmitted ? moment(RFQ.dtSubmitted).format("MM/DD/YYYY") : ""}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </>
                )}
            </div>
        );
    }
}

export default FollowupEditor;